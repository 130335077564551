
// import { checkPage } from '@/hooks/global'
import {
  inject,
  ref,
  onMounted,
  nextTick,
  onDeactivated,
  onActivated,
  computed
} from 'vue'
import { isDebugMode, checkPage } from '@/hooks/global'
import { onBeforeRouteLeave, useRouter } from 'vue-router'

import CommonButton from '@/components/CommonButton.vue'
import GameHeader from '@/components/GameHeader.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import CircleLoader from '@/components/CircleLoader.vue'
import HeadphonesIcon from '@/assets/svg/headphones.svg'
import anime from 'animejs/lib/anime.es.js'
import { FX_URL, MUSIC_URL } from '@/webgl/config'
import { useHowler } from '../plugins/Howler'
import store from '@/store'
import timeout from '@/utils/timeout'
import { useGtm } from 'vue-gtm'

const TO_LOAD = [
  {
    type: 'sound',
    name: 'layer1',
    loop: true,
    path: MUSIC_URL + 'layer1.mp3'
  },
  {
    type: 'sound',
    name: 'layer2',
    loop: true,
    path: MUSIC_URL + 'layer2.mp3'
  },
  {
    type: 'sound',
    name: 'popup',
    loop: false,
    path: FX_URL + 'popup.mp3'
  }
]

export default {
  name: 'GameIntro',
  components: {
    CommonButton,
    GameHeader,
    SiteHeader,
    CircleLoader,
    HeadphonesIcon
  },
  setup() {
    const gtm = useGtm()
    const check = checkPage()
    const router = useRouter()
    const howler = useHowler()

    const t = inject('$t')

    const isDebug = ref(isDebugMode())
    const isActive = ref(false)
    const isLoading = ref(true)
    const toGame = ref(false)
    const active = ref(false)
    const tick = ref(null)
    const skipAnim = computed(() => store.getters['global/skipHomeLoader'])

    const circleLoader = ref(null)
    const video = ref(null)

    let tl = null
    let root = null

    onBeforeRouteLeave((to, from) => {
      toGame.value = to.name === 'Game'
    })

    const loadSounds = () =>
      Promise.all(
        TO_LOAD.map(
          asset =>
            new Promise(resolve =>
              howler.$getSound(asset.name, false, {
                srcs: [asset.path],
                preload: true,
                html5: false,
                preloadCallback: () => resolve(true),
                opts: {
                  loop: asset.loop
                }
              })
            )
        )
      )

    const reset = () => {
      isLoading.value = true

      toGame.value = false
      if (video.value) {
        video.value.pause()
      }
    }

    const onEnter = (el: Element) => {
      loadSounds()
      active.value = true
      nextTick(() => {
        reset()
        //FAKE LOADING TO CHANGE !!!!!
        if (store.getters['global/howlerLayer1'] === null) {
          store.commit(
            'global/setHowlerLayer1',
            howler.$getSound('layer1').play()
          )
        }
        root = el
        const introLoaderBackground = el.querySelector(
          '.intro-loader-background'
        )
        const introLoaderSun = el.querySelector('.intro-loader-sun')
        const introLoaderWrapper = el.querySelector('.intro-loader > .wrapper')
        if (!introLoaderWrapper || !introLoaderWrapper.querySelector) return
        const introLoaderFooter = introLoaderWrapper.querySelector(
          '.intro-loader-footer'
        )
        const introLoaderFooterAudio = introLoaderWrapper.querySelector(
          '.intro-loader-headphones'
        )
        const introLoaderSpanTitle = introLoaderWrapper.querySelectorAll(
          '.intro-loader-title-container p > span'
        )
        const introLoaderDivide = introLoaderWrapper.querySelector(
          '.intro-loader-divider'
        )
        const introLoaderText = introLoaderWrapper.querySelectorAll(
          '.intro-loader-text'
        )

        tl = anime.timeline({
          delay: 2000
        })

        tl.add(
          {
            duration: 1200,
            targets: introLoaderBackground,
            easing: 'linear',
            opacity: [0, 0.6]
          },
          0
        )
          .add(
            {
              duration: 1200,
              targets: introLoaderSun,
              easing: 'linear',
              translateX: ['-50%', '-50%'],
              translateY: ['-50%', '-50%'],
              scale: [1.2, 1],
              opacity: [0, 1]
            },
            0
          )
          // Footer animation
          .add(
            {
              targets: introLoaderFooter,
              duration: 1367,
              translateY: ['100%', 0],
              easing: 'cubicBezier(0.78, 0, 0.14, 1)'
            },
            400
          )
          .add(
            {
              complete: () => {
                howler.$getSound('popup').play()
              }
            },
            1500
          )

          .add(
            {
              targets: introLoaderFooterAudio,
              duration: 760,
              opacity: [0, 1],
              easing: 'linear'
            },
            1300
          )
          .add(
            {
              targets: introLoaderSpanTitle,
              duration: 1433,
              translateY: [60, 0],
              easing: 'cubicBezier(0.51, 0, 0.18, 1)',
              delay: anime.stagger(133)
            },
            2500
          )
          .add(
            {
              targets: introLoaderDivide,
              duration: 733,
              scaleX: [0, 1],
              easing: 'cubicBezier(0.09, 0.55, 0.1, 1)'
            },
            1800
          )
          .add(
            {
              targets: introLoaderText,
              duration: 733,
              opacity: [0, 1],
              easing: 'cubicBezier(0.33, 0, 0.67, 1)'
            },
            2100
          )

        if (skipAnim.value) {
          // tl.pause()
          anime({
            targets: el,
            duration: 733,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            opacity: [0, 1]
          })

          tl.seek(tl.duration)

          onLoaded()
          // console.log(tl.duration)
          // isLoading.value = false
          // nextTick(secondTimeline)
        } else {
          gtm.trackEvent({
            event: 'vpv',
            VPV: 'loader'
          })
        }
      })
    }

    const onLeave = (el: Element, done) => {
      anime({
        targets: document.body,
        backgroundColor: '#ffecd2',
        duration: 300,
        easing: 'linear',
        complete: () => {
          reset()
          active.value = false
          done()
        }
      })
    }

    const onLoaded = () => {
      if (check)
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'homepage'
        })
      // if (!active.value) return
      const introLoaderHeader = root.querySelector('.hw-header svg')
      const introLoaderBackground = root.querySelector(
        '.intro-loader-background'
      )
      const introLoaderSun = root.querySelector('.intro-loader-sun')
      const introLoaderWrapper = root.querySelector('.intro-loader > .wrapper')

      if (!introLoaderWrapper || !introLoaderWrapper.querySelector) return

      const introLoaderFooter = introLoaderWrapper.querySelector(
        '.intro-loader-footer'
      )
      const introLoaderTitle = introLoaderWrapper.querySelector(
        '.intro-loader-title-container'
      )
      const introLoaderDivide = introLoaderWrapper.querySelector(
        '.intro-loader-divider'
      )
      const introLoaderText = introLoaderWrapper.querySelectorAll(
        '.intro-loader-text'
      )
      circleLoader.value && circleLoader.value.onLeave()
      tl = anime.timeline()
      tl.add(
        {
          targets: introLoaderFooter,
          duration: 1367,
          translateY: '100%',
          easing: 'cubicBezier(0.9, 0, 0.1, 1)',
          begin: () => {
            setTimeout(() => {
              howler.$getSound('popup').play()
            }, 100)
          }
        },
        0
      )
        .add(
          {
            targets: introLoaderHeader,
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          0
        )
        .add(
          {
            targets: introLoaderTitle,
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          292
        )
        .add(
          {
            targets: introLoaderDivide,
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          486
        )
        .add(
          {
            targets: introLoaderText,
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            begin: () => {
              if (video.value) {
                video.value
                  .play()
                  .then()
                  .catch(e => {
                    console.log(e)
                    video.value.currentTime = video.value.duration - 0.1
                    console.log('prout', video.value.currentTime)
                  })
              }
              howler.$getSound('layer1').once('fade', () => {
                howler.$getSound('layer1').stop()
              })
              howler.$getSound('layer1').fade(1, 0, 2000)

              howler.$getSound('layer2').play()
              howler.$getSound('layer2').fade(0, 1, 2000)
            },
            complete: () => {
              store.commit('global/setSkipHomeLoader', true)
              isLoading.value = false
              nextTick(secondTimeline)
            }
          },
          681
        )

      if (skipAnim.value) {
        tl.seek(tl.duration)
        if (video.value) video.value.play()
        howler.$getSound('layer1').once('fade', () => {
          howler.$getSound('layer1').stop()
        })
        howler.$getSound('layer1').fade(1, 0, 2000)

        howler.$getSound('layer2').play()
        howler.$getSound('layer2').fade(0, 1, 2000)

        anime.set(introLoaderSun, {
          opacity: 0
        })
        anime({
          targets: video.value,
          duration: 1119,
          easing: 'cubicBezier(0.33, 0, 0.67, 1)',
          opacity: [0, 1]
        })
      }
    }

    const secondTimeline = () => {
      const introLoaderBackground = root.querySelector(
        '.intro-loader-background'
      )
      const introLoaderSun = root.querySelector('.intro-loader-sun')
      const introLogo = root.querySelector('.intro-page-logo')
      const introHeaderLine = root.querySelector('.game-header .bottom-line')

      tl = anime.timeline()

      tl.add(
        {
          targets: introLoaderSun,
          duration: 1119,
          opacity: 0,
          easing: 'cubicBezier(0.33, 0, 0.67, 1)'
        },
        0
      )
        .add(
          {
            targets: introLoaderBackground,
            duration: 1119,
            opacity: 1,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          0
        )
        .add(
          {
            targets: introHeaderLine,
            duration: 1900,
            translateY: [43, 0],
            easing: 'cubicBezier(0.48, 0, 0.1, 1)'
          },
          0
        )
        .add(
          {
            targets: introLogo,
            duration: 1367,
            scale: [1.1, 1],
            opacity: [0, 1],
            easing: 'cubicBezier(0.48, 0, 0.1, 1)'
          },
          800
        )
    }

    const replayTl = () => {
      tl.play()
    }

    const seekTl = e => {
      tl.pause()
      tl.seek(tl.duration * (~~e.target.value / 100))
    }

    const onTick = () => {
      if (video.value && active.value) {
        if (video.value.duration) {
          if (video.value.currentTime >= video.value.duration - 0.1) {
            video.value.currentTime = 4
            video.value.play()
          }
        }
        tick.value = window.requestAnimationFrame(onTick)
      }
    }

    const tryFullScreen = () => {
      const elem = document.body
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      }
    }

    // onMounted(() => (active.value = true))
    onActivated(() => {
      howler.$getSound('layer2').stop()
      active.value = true
      nextTick(onTick)

      document.body.style.background = '#e36b45'
    })
    onDeactivated(() => {
      window.cancelAnimationFrame(tick.value)

      document.body.style.background = ''
    })

    return {
      t,
      tryFullScreen,
      isLoading,
      isDebug,
      router,
      onEnter,
      onLeave,
      replayTl,
      seekTl,
      circleLoader,
      video,
      active,
      onLoaded,
      skipAnim
    }
  }
}
