<template>
  <transition
    name="leaderboard"
    mode="out-in"
    :appear="true"
    @enter="onEnter"
    @leave="onLeave"
  >
    <div class="leaderboard">
      <site-header :logo="true" :logo-left-aligned="true" :borders="true" />
      <div class="scroll-container">
        <leaderboard-table v-if="best" :best="best" />
        <div v-if="hasTable" class="form-leaderboard__btns">
          <common-button
            medium
            :appear="700"
            :label="t('Home.cta-start')"
            icon="replay"
            to="/game"
            :tracking="{
              event: 'eventPush',
              eventAction: 'replay',
              eventLabel: 'Replay on leaderboard',
              eventValue: '',
              eventCategory: 'cta'
            }"
          />
          <common-button
            :appear="800"
            :label="t('ThankYou.share-ty')"
            icon="share"
            outline
            medium
            color="red"
            :tracking="{
              event: 'eventPush',
              eventAction: '',
              eventLabel: '',
              eventValue: '',
              eventCategory: 'sharing'
            }"
            @click="share"
          />
        </div>
        <div class="buy">
          <div class="buy__bg">
            <img src="@/assets/images/submission-bg.jpg" alt="" />
          </div>
          <div class="buy__front">
            <img src="@/assets/images/submission-front.png" alt="" />
          </div>
          <div class="buy__content">
            <!-- <p class="main">{{ t('Leaderboard.footer-leader') }}</p>
            <p class="snd">{{ t('Leaderboard.footer-sec') }}</p> -->

            <common-button
              class="t-btn-primary--yellow"
              :appear="1000"
              :label="t('Leaderboard.buy-leader')"
              small
              outline
              :tracking="{
                event: 'eventPush',
                eventAction: 'visit-buyhighwest',
                eventLabel: '',
                eventValue: '',
                eventCategory: 'navigation'
              }"
              to="https://buy.highwest.com/"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { checkPage } from '@/hooks/global'
import LeaderboardTable from '@/components/LeaderBoardTable.vue'
import SiteHeader from '@/components/GameHeader.vue'

import { inject, onActivated, onDeactivated, ref } from 'vue'
import store from '@/store'

import anime from 'animejs/lib/anime.es.js'
import CommonButton from '@/components/CommonButton.vue'

export default {
  name: 'Leaderboard',
  components: {
    CommonButton,
    LeaderboardTable,
    SiteHeader
  },
  setup() {
    checkPage()
    const t = inject('$t')
    const SCORE_API = process.env.VUE_APP_SCORE_API
    const hasTable = ref(false)

    const best = ref(null)

    const getBest = async () => {
      const rawResponse = await fetch(`${SCORE_API}score/best`)
      const data = await rawResponse.json()

      best.value = data

      hasTable.value = true

      anime({
        targets: '.buy',
        delay: 500,
        duration: 500,
        easing: 'easeOutSine',
        opacity: 1
      })
    }

    const share = () => {
      if (!navigator.share) {
        return
      }

      console.log('share')

      navigator.share({
        title: document.title,
        url: window.location.href
      })
    }

    onActivated(() => {
      // onEnter()
    })

    onDeactivated(() => {
      hasTable.value = false
      document.body.style.background = ''
      anime.set('.fake-borders', {
        opacity: ''
      })
      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: false
      })

      best.value = false
    })

    const onEnter = (el, done) => {
      hasTable.value = false
      window.scrollTo(0, 0)
      getBest()
      anime.set('.buy', {
        opacity: 0
      })
      anime.set('.fake-bg', {
        backgroundColor: ''
      })
      anime({
        targets: el,
        duration: 330,
        easing: 'linear',
        opacity: [0, 1],
        complete: () => {
          document.body.style.background = 'rgb(252, 211, 159)'
          store.commit('global/toggleFrontGridVisibility', {
            isFrontGridHidden: true
          })

          anime.set('.fake-bg', {
            backgroundColor: 'rgb(252, 211, 159)'
          })

          anime.set('.fake-borders', {
            opacity: 0
          })
          done()
        }
      })
    }
    const onLeave = (el, done) => {
      anime({
        targets: '.fake-bg, .fake-borders',
        duration: 200,
        easing: 'linear',
        opacity: 0
      })
      anime({
        targets: el,
        duration: 200,
        easing: 'linear',
        opacity: 0,
        complete: () => {
          anime.set('.fake-borders', {
            opacity: ''
          })
          anime.set('.fake-bg', {
            opacity: ''
          })
          best.value = false
          done()
        }
      })
    }

    return {
      t,
      hasTable,
      share,
      onEnter,
      onLeave,
      best
    }
  }
}
</script>

<style lang="scss" scoped>
.leaderboard {
  display: flex;
  // background-color: #ffecd2;
  min-height: 100%;

  &.leaderboard-enter-to {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    width: 100%;
  }
  &.leaderboard-leave-to {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }

  .sharing-box {
    position: absolute;
    z-index: -2;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    overflow: hidden;
  }

  .form-leaderboard__btns {
    display: flex;
    justify-content: center;
    margin-top: 26px;

    .common-button:first-child {
      margin-right: 19px;
    }
  }

  .buy {
    position: relative;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-top: auto;
    opacity: 0;

    .buy__bg,
    .buy__front {
      position: absolute;
      top: 0px;
      left: -16px;
      right: -16px;
      bottom: -16px;
      z-index: -1;

      img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        img {
          object-position: top center;
        }
      }
    }

    .buy__bg {
      top: -330px;
      bottom: auto;
      height: 355px;

      img {
        object-position: 20% 100%;
        opacity: 0.5;
      }
    }
    .buy__front {
      top: -50px;

      img {
        object-position: 10% 0%;
      }
    }

    .buy__content {
      text-align: center;
      color: #fff;
      margin-top: 110px;

      .main {
        font-size: 15px;
        text-transform: uppercase;
        max-width: 220px;
        margin: 0 auto;
        margin-bottom: 0px;
      }
      .snd {
        font-size: 14px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 14px;
        max-width: 300px;
      }
    }
  }
}
</style>
