
import { indicator } from 'ordinal'
import store from '@/store'
import { computed, onBeforeMount, ref } from '@vue/runtime-core'
import { checkPage } from '@/hooks/global'

export default {
  setup() {
    checkPage()
    const SCORE_API = process.env.VUE_APP_SCORE_API

    const score = computed(() => store.getters['game/score'])
    const rank = ref(null)
    const simpleRank = ref(null)

    const getRank = async () => {
      const rawResponse = await fetch(`${SCORE_API}score/test/${score.value}`)
      const { position } = await rawResponse.json()

      const indi = indicator(position + 1)
      simpleRank.value = position + 1
      rank.value = `${position + 1}<sup>${indi}</sup>`
    }

    onBeforeMount(async () => {
      await getRank()
    })

    return { score, rank, simpleRank }
  }
}
