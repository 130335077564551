
export default {
  name: 'VideoOverlay',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closed']
}
