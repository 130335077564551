<template>
  <div class="submission" :class="{ 'no-score': !score }">
    <site-header :logo="true" :logo-left-aligned="true" :borders="true" />
    <div class="scroll-container">
      <div v-show="!isLeaderboard" class="submission__header">
        <div v-show="isThanks" class="submission__thanks">
          <div class="thanks-check">
            <div class="check-circle" />
            <svg
              width="56"
              height="39"
              viewBox="0 0 56 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M53.235 2.42773L20.5083 35.1482L2.70166 17.3453"
                stroke="#D8635F"
                stroke-width="6.62328"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p class="thanks-title">{{ t('ThankYou.main-ty') }}</p>
        </div>

        <score-rank v-if="score" ref="scoreRankEl" />

        <div v-if="score" v-show="isThanks" class="separator" />

        <p class="submission__welcoming">{{ t('Form.main-form') }}</p>
        <p class="submission__infos">{{ t('Form.sub-form') }}</p>
        <p class="submission__us" v-html="t('Form.us-form')" />

        <common-button
          v-if="!score && isThanks"
          :appear="800"
          label="SPREAD THE WORD"
          icon="share"
          outline
          medium
          color="red"
          :tracking="{
            event: 'eventPush',
            eventAction: '',
            eventLabel: '',
            eventValue: '',
            eventCategory: 'sharing'
          }"
        />
      </div>

      <form class="t-form" @submit.prevent="checkForm">
        <div class="submission-form-block">
          <label class="t-label" for="firstname">{{ t('Form.fn-form') }}</label>
          <input
            id="firstname"
            v-model="firstname"
            name="firstname"
            type="text"
            class="t-input--text"
            @keyup="validateForm"
            @focus="onInputFocus"
            @blur="onInputBlur"
          />
          <p v-if="isSend && firstname.length === 0" class="t-text--sm error">
            How ‘bout a friendly introduction?
          </p>
        </div>
        <div class="submission-form-block">
          <label class="t-label" for="name">{{ t('Form.ln-form') }}</label>
          <input
            id="name"
            v-model="name"
            name="name"
            type="text"
            class="t-input--text"
            @keyup="validateForm"
            @focus="onInputFocus"
            @blur="onInputBlur"
          />
          <p v-if="isSend && name.length === 0" class="t-text--sm error">
            How ‘bout a friendly introduction?
          </p>
        </div>
        <div class="submission-form-block">
          <label class="t-label t-label--arrow" for="birthday">{{
            t('Form.bd-form')
          }}</label>
          <input
            id="birthday"
            v-model="birthday"
            name="birthday"
            type="date"
            class="t-input--text "
            @keyup="validateForm"
            @focus="onInputFocus"
            @blur="onInputBlur"
          />

          <p v-if="isSend && birthday.length === 0" class="t-text--sm error">
            Not to offend, but DOB is required
          </p>
          <p
            v-if="isSend && birthday.length > 0 && !birthdayValidation"
            class="t-text--sm error"
          >
            Only 21+ in these parts
          </p>
        </div>
        <div class="submission-form-block">
          <label class="t-label t-label--arrow" for="state">{{
            t('Form.state-form')
          }}</label>
          <select
            id="state"
            v-model="state"
            class="t-input--text"
            name="state"
            @focus="onInputFocus"
            @blur="onInputBlur"
          >
            <option v-for="(code, name) in allStates" :key="name" :value="code">
              {{ name }}
            </option>
          </select>
          <p v-if="isSend && state.length === 0" class="t-text--sm error">
            State where you reside, please
          </p>
        </div>
        <div class="submission-form-block">
          <label class="t-label" for="email">{{ t('Form.mail-form') }}</label>
          <input
            id="email"
            v-model="email"
            name="email"
            type="email"
            class="t-input--text"
            :class="{ 't-text': email.length > 20 }"
            @keyup="validateForm"
            @focus="onInputFocus"
            @blur="onInputBlur"
          />
          <p v-if="isSend && email.length === 0" class="t-text--sm error">
            Electronic mail address, please
          </p>
          <p
            v-if="isSend && email.length > 0 && !emailValidation"
            class="t-text--sm error"
          >
            That email needs correctin’
          </p>
        </div>
        <div class="submission-form-block">
          <label class="t-label" for="phone">
            {{ t('Form.phone-form') }} <span class="opt">optional</span>
          </label>
          <input
            id="phone"
            v-model="phone"
            name="phone"
            type="tel"
            class="t-input--text"
            @keyup="validateForm"
            @focus="onInputFocus"
            @blur="onInputBlur"
          />
          <p v-if="isSend && !phoneValidation" class="t-text--sm error">
            That number needs correctin’
          </p>
        </div>
        <div class="submission-form-checkbox">
          <input
            id="rules"
            v-model="rules"
            name="rules"
            type="checkbox"
            class="t-input--checkbox"
          />
          <label
            class="t-label"
            :class="{ error: isSend && !rules }"
            for="rules"
            v-html="
              t('Form.rules-form') + (isSend && !rules ? '(required)' : '')
            "
          />

          <!-- <p v-if="isSend && already_entered" class="t-text--sm error">
            This email has already been used.
          </p> -->
        </div>
        <div class="submission-form-checkbox">
          <input
            id="newsletter"
            v-model="newsletter"
            name="newsletter"
            type="checkbox"
            class="t-input--checkbox"
          />
          <label class="t-label" for="newsletter">
            {{ t('Form.club-form') }}
            <span>Optional</span>
            <!-- I'd like to be contacted about joining the
            <a href="#">High West Whiskey Club</a> -->
          </label>
        </div>

        <div class="submission-form-submit">
          <common-button
            type="submit"
            label="Submit"
            :appear="800"
            :disabled="sendForm"
          />
        </div>
      </form>

      <div v-show="isLeaderboard" class="leaderboard">
        <leaderboard-table
          v-if="isLeaderboard"
          :id="leaderboard.id"
          :best="leaderboard.best"
          :siblings="leaderboard.siblings"
        />
      </div>

      <div
        v-show="(isThanks || isLeaderboard) && score"
        class="submission__form-leaderboard"
      >
        <p class="form-leaderboard__desc">
          {{ t('ThankYou.sub-ty') }}
        </p>

        <form
          class="form-leaderboard__form"
          @submit.prevent="checkFormLeaderboard"
        >
          <input
            v-model="username"
            type="text"
            placeholder="ABC"
            minlength="3"
            maxlength="3"
            required
          />
          <button type="submit" :disabled="sendForm">
            {{ t('ThankYou.add-ty') }}
          </button>
        </form>
        <p v-if="wordBanned" class="t-text--sm error">
          Beg your pardon?
        </p>

        <div class="form-leaderboard__btns">
          <common-button
            v-if="isThanks || isLeaderboard"
            medium
            :appear="700"
            :label="t('Score.replay-score-cta')"
            icon="replay"
            to="/game"
            :tracking="{
              event: 'eventPush',
              eventAction: 'replay',
              eventLabel: 'Replay on sweepstakes form',
              eventValue: '',
              eventCategory: 'cta'
            }"
          />
          <!-- <common-button
            v-if="isThanks || isLeaderboard"
            :appear="800"
            :label="t('ThankYou.share-ty')"
            icon="share"
            outline
            medium
            color="red"
            @click="downloadImage"
          /> -->
        </div>

        <!-- <div v-if="scoreRankEl" class="sharing-box">
          <sharing-score
            ref="sharingScore"
            :score="score"
            :rank="rank"
            :name="username"
          />
        </div> -->
      </div>

      <div v-show="isThanks || isLeaderboard" class="buy">
        <div class="buy__bg">
          <img src="@/assets/images/submission-bg.jpg" alt="" />
        </div>
        <div class="buy__front">
          <img src="@/assets/images/submission-front.png" alt="" />
        </div>
        <div class="buy__content">
          <p class="main">
            {{
              !score
                ? t('NoScoreThankYou.mainfooter-nsty')
                : t('ThankYou.mainfooter-ty')
            }}
          </p>
          <p class="snd">
            {{
              !score
                ? t('NoScoreThankYou.footer-nsty')
                : t('ThankYou.footer-ty')
            }}
          </p>

          <common-button
            v-if="isThanks || isLeaderboard"
            class="t-btn-primary--yellow"
            :appear="1000"
            :label="t('ThankYou.buy-ty')"
            to="https://buy.highwest.com/"
            small
            outline
            :tracking="{
              event: 'eventPush',
              eventAction: 'visit-buyhighwest',
              eventLabel: '',
              eventValue: '',
              eventCategory: 'navigation'
            }"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isSend && already_entered"
      class="already-entered"
      :class="{ open: popinOpen }"
    >
      <div class="already-entered-wrapper">
        <button class="t-btn close" @click="closePopinAlreadyEnter">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="cross"
          >
            <path
              d="M1.085 1.211L18.763 18.89M18.763 1.211L1.086 18.89"
              stroke="#F28E6C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="bevel"
            />
          </svg>
        </button>
        <div class="already-entered-wrapper-inside">
          <p class="submission__welcoming" v-html="t('Form.popin-text')" />
          <common-button
            medium
            :appear="700"
            :label="t('Score.replay-score-cta')"
            icon="replay"
            to="/game"
            :tracking="{
              event: 'eventPush',
              eventAction: 'replay',
              eventLabel: 'Replay on sweepstakes form',
              eventValue: '',
              eventCategory: 'cta'
            }"
          />
          <!-- <common-button
            v-if="score > 0"
            color="red"
            :appear="1000"
            :label="t('Form.popin-button')"
            small
            outline
            :tracking="{
              event: 'eventPush',
              eventAction: 'visit-buyhighwest',
              eventLabel: '',
              eventValue: '',
              eventCategory: 'navigation'
            }"
            @click="goThankYouNoSubmit"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  checkPage,
  hasFakeScore,
  isDebugMode,
  watchResize
} from '@/hooks/global'
import {
  reactive,
  toRefs,
  inject,
  onMounted,
  onActivated,
  onDeactivated,
  ref,
  nextTick,
  computed
} from 'vue'
import store from '@/store'
import SiteHeader from '@/components/GameHeader.vue'
import ScoreRank from '@/components/ScoreRank.vue'
import CommonButton from '@/components/CommonButton.vue'
// import SharingScore from '@/components/SharingScore.vue'

import moment from 'moment'

import banWord from '@/utils/WordBan'

import LeaderboardTable from '@/components/LeaderBoardTable.vue'

import { load } from 'recaptcha-v3'
import anime from 'animejs/lib/anime.es.js'
import { useGtm } from 'vue-gtm'
import timeout from '@/utils/timeout'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
function validateUSPhonenumber(phone) {
  const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  return re.test(String(phone).toLowerCase())
}

export default {
  components: {
    SiteHeader,
    ScoreRank,
    CommonButton,
    LeaderboardTable
    // SharingScore
  },
  setup() {
    const gtm = useGtm()
    const check = checkPage()
    const t = inject('$t')
    const isDebug = ref(isDebugMode())
    const hasScore = ref(hasFakeScore())

    if (hasScore.value) {
      store.commit('game/setScore', { score: hasScore.value })
    }

    const SCORE_API = process.env.VUE_APP_SCORE_API
    const REGISTRATION_API = process.env.VUE_APP_REGISTRATION_API
    const REGISTRATION_TOKEN = process.env.VUE_APP_REGISTRATION_TOKEN

    const wordBanned = ref(false)
    const sendForm = ref(false)
    const isThanks = ref(false)
    const isLeaderboard = ref(false)
    const isSubmitting = ref(false)
    const popinOpen = ref(false)
    const leaderboard = ref(null)
    // const sharingScore = ref(null)
    const scoreRankEl = ref(null)

    const allStates = ref({
      ALABAMA: 'AL',
      ALASKA: 'AK',
      ARIZONA: 'AZ',
      ARKANSAS: 'AR',
      CALIFORNIA: 'CA',
      COLORADO: 'CO',
      CONNECTICUT: 'CT',
      'DISTRICT OF COLUMBIA (D.C.)': 'DC',
      DELAWARE: 'DE',
      FLORIDA: 'FL',
      GEORGIA: 'GA',
      HAWAII: 'HI',
      IDAHO: 'ID',
      ILLINOIS: 'IL',
      INDIANA: 'IN',
      IOWA: 'IA',
      KANSAS: 'KS',
      KENTUCKY: 'KY',
      LOUISIANA: 'LA',
      MAINE: 'ME',
      MARYLAND: 'MD',
      MASSACHUSETTS: 'MA',
      MICHIGAN: 'MI',
      MINNESOTA: 'MN',
      MISSISSIPPI: 'MS',
      MISSOURI: 'MO',
      MONTANA: 'MT',
      NEBRASKA: 'NE',
      NEVADA: 'NV',
      'NEW HAMPSHIRE': 'NH',
      'NEW JERSEY': 'NJ',
      'NEW MEXICO': 'NM',
      'NEW YORK': 'NY',
      'NORTH CAROLINA': 'NC',
      'NORTH DAKOTA': 'ND',
      OHIO: 'OH',
      OKLAHOMA: 'OK',
      OREGON: 'OR',
      PENNSYLVANIA: 'PA',
      'RHODE ISLAND': 'RI',
      'SOUTH CAROLINA': 'SC',
      'SOUTH DAKOTA': 'SD',
      TENNESSEE: 'TN',
      TEXAS: 'TX',
      UTAH: 'UT',
      VERMONT: 'VT',
      VIRGINIA: 'VA',
      WASHINGTON: 'WA',
      'WEST VIRGINIA': 'WV',
      WISCONSIN: 'WI',
      WYOMING: 'WY'
    })

    const score = computed(() => store.getters['game/score'])

    const rank = computed(() => {
      return scoreRankEl.value.simpleRank
    })

    let unwatch = null

    const form = reactive({
      isSend: false,
      validate: false,
      firstname: '',
      name: '',
      username: '',
      birthday: '',
      state: '',
      email: '',
      phone: '',
      rules: false,
      newsletter: false,
      birthdayValidation: false,
      phoneValidation: false,
      emailValidation: false,
      already_entered: false,
      recaptcha: null
    })

    let tlEnter = null

    const onInputFocus = (ev, type) => {
      const parent = ev.target.parentNode
      parent.classList.add('submission-form-block--filled')
    }

    const onInputBlur = ev => {
      if (ev.target.value.trim() === '') {
        const parent = ev.target.parentNode
        parent.classList.remove('submission-form-block--filled')
      }
    }

    const isOver21 = () => {
      const birthdate = moment(`${form.birthday}`, 'YYYY-MM-DD')
      if (!birthdate.isValid()) return

      return birthdate.isBefore(moment().subtract(21, 'years'))
    }

    const validateForm = () => {
      form.already_entered = false
      form.emailValidation = validateEmail(form.email)
      form.phoneValidation =
        validateUSPhonenumber(form.phone) || form.phone.length === 0
      form.birthdayValidation = isOver21()
      if (
        form.firstname.length > 0 &&
        form.name.length > 0 &&
        form.state.length > 0 &&
        form.email.length > 0 &&
        form.rules
      ) {
        form.validate = form.emailValidation && form.birthdayValidation
        if (form.phone.length > 0) {
          form.validate = form.phoneValidation
        } else form.phoneValidation = true
      } else {
        form.validate = false
      }
    }

    const checkForm = async () => {
      if (isSubmitting.value) return
      isSubmitting.value = true
      form.isSend = true

      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'submitform',
        eventLabel: '',
        eventValue: score.value ? 0 : 1,
        eventCategory: 'cta'
      })

      validateForm()
      if (form.validate) {
        const [year, month, day] = form.birthday.split('-')

        let params = {
          firstname: form.firstname,
          lastname: form.name,
          state: form.state,
          day,
          month,
          year,
          email: form.email,
          phonenumber: form.phone.length < 1 ? '' : form.phone,
          highwestwhiskeyclub: form.newsletter,
          accessibleregistration: score.value ? false : true,
          score: score.value ? score.value : 0
        }

        if (!isDebug.value) {
          sendForm.value = true
          const rawResponse = await fetch(REGISTRATION_API, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${REGISTRATION_TOKEN}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
          })

          const { errors, status } = await rawResponse.json()
          let logErrors = ''
          if (errors) {
            for (const error of errors) {
              logErrors += error + ' '
              console.log('error')
              switch (error) {
                case 'state':
                  form.state = ''
                  break
                case 'phonenumber':
                  form.phoneValidation = false
                  break
                default:
                  form.validate = false
              }
            }
            sendForm.value = false
            isSubmitting.value = false
          } else if (status === 'success') {
            onSubmitDone()
          } else {
            form.already_entered = true
            form.validate = false
            isSubmitting.value = false
            sendForm.value = false
            openPopinAlreadyEnter()
          }
          gtm.trackEvent({
            event: 'eventPush',
            eventAction: 'success',
            eventLabel: 'error message',
            eventValue: errors ? logErrors : 1,
            eventCategory: 'register'
          })
        } else {
          onSubmitDone()
        }
      } else {
        window.scrollTo(0, 0)
        isSubmitting.value = false
      }
    }

    const checkFormLeaderboard = async () => {
      if (isSubmitting.value) return

      gtm.trackEvent({
        event: 'eventPush',
        eventAction: '',
        eventLabel: '',
        eventValue: '',
        eventCategory: 'cta'
      })

      wordBanned.value = banWord(form.username.toLowerCase())
      if (wordBanned.value) {
        gtm.trackEvent({
          event: 'eventPush',
          eventAction: 'profanity',
          eventLabel: '',
          eventValue: '',
          eventCategory: 'profanity'
        })
        return
      }

      isSubmitting.value = true
      sendForm.value = true

      const recaptcha = await load(process.env.VUE_APP_SCORE_API_RECAPTCHA, {
        autoHideBadge: true
      })
      const token = await recaptcha.execute('submit')

      let params = {
        score: `${score.value}`,
        username: form.username,
        'g-recaptcha-response': token
      }
      let body = new URLSearchParams(params)

      try {
        const rawResponse = await fetch(`${SCORE_API}score/save`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body
        })

        const data = await rawResponse.json()

        leaderboard.value = data
        onSubmitLeaderBoardDone()
        isSubmitting.value = false
      } catch (error) {
        isSubmitting.value = false
      }
      sendForm.value = false
    }

    const onEnter = () => {
      window.scrollTo(0, 0)
      let tlEnter = anime.timeline({
        complete: () => {
          unwatch()
        }
      })

      tlEnter.add({
        targets: '.t-form > div',
        duration: 333,
        delay: anime.stagger(88, { start: 300 }),
        opacity: [0, 1],
        easing: 'cubicBezier(0.33, 0, 0.67, 1)'
      })
    }

    const onSubmitLeaderBoardDone = () => {
      gtm.trackEvent({
        event: 'vpv',
        VPV: 'confirmation-ame'
      })
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement

      const tl = anime.timeline({
        complete: () => {
          isThanks.value = false
          isLeaderboard.value = true

          anime.set(
            '.submission__header, .form-leaderboard__desc, .form-leaderboard__form',
            {
              display: 'none'
            }
          )
        }
      })
      tl.add(
        {
          targets: scrollElement,
          duration: 400,
          scrollTop: 0,
          easing: 'cubicBezier(0.33, 0, 0.67, 1)'
        },
        0
      ).add({
        targets:
          '.submission__header, .form-leaderboard__desc, .form-leaderboard__form',
        duration: 667,
        opacity: 0,
        easing: 'cubicBezier(0.43, 0, 0.78, 1)'
      })
    }

    const onSubmitDone = () => {
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement

      if (score.value) {
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'leaderboard-form'
        })
      } else {
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'confirmation-ame'
        })
      }
      const tl = anime.timeline({
        delay: 1000,
        complete: () => {
          sendForm.value = false
          isThanks.value = true
          isSubmitting.value = false
          nextTick(onThanks)
        }
      })
      tl.add(
        {
          targets: scrollElement,
          duration: 400,
          scrollTop: 0,
          easing: 'cubicBezier(0.33, 0, 0.67, 1)'
        },
        0
      )
        .add(
          {
            targets: 'body, .game-header .fake-bg',
            duration: 400,
            backgroundColor: '#fcd39f',
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          400
        )
        .add(
          {
            targets: '.t-form > div, .submission__header > p',
            duration: 333,
            delay: anime.stagger(88, { direction: 'reverse' }),
            opacity: 0,
            display: 'none',
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            complete: anim => {
              anim.animatables.forEach(element => {
                element.target.style.display = 'none'
              })
            }
          },
          400
        )
    }

    const onThanks = () => {
      const tl = anime.timeline()
      tl.add(
        {
          targets: '.submission__thanks',
          duration: 567,
          height: [0, 158],
          easing: 'cubicBezier(0.7, 0, 0.34, 1)'
        },
        0
      )
        .add(
          {
            targets: '.submission__header .separator',
            duration: 567,
            opacity: [0, 1],
            translateY: [-20, 0],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          0
        )
        .add(
          {
            targets: '.buy__bg',
            duration: 1967,
            translateY: ['80%', 0],
            scale: [1.4, 1],
            opacity: [0, 1],
            easing: 'cubicBezier(0.68, 0.08, 0.27, 1)'
          },
          0
        )
        .add(
          {
            targets: '.buy__front',
            duration: 1967,
            translateY: ['120%', 0],
            scale: [1.4, 1],
            opacity: [0, 1],
            easing: 'cubicBezier(0.68, 0.08, 0.27, 1)'
          },
          0
        )
        .add(
          {
            targets: '.submission__header .check-circle',
            duration: 636,
            scale: [0, 1],
            easing: 'cubicBezier(0.66, 0, 0.34, 1)'
          },
          233
        )
        .add(
          {
            targets: '.submission__header .thanks-check path',
            duration: 617,
            strokeDashoffset: [73, 146],
            easing: 'cubicBezier(0.76, 0, 0.26, 1)'
          },
          233
        )
        .add(
          {
            targets: '.submission__header .thanks-title',
            duration: 533,
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          367
        )
        .add(
          {
            targets: '.form-leaderboard__desc',
            duration: 467,
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          400
        )
        .add(
          {
            targets: '.form-leaderboard__form',
            duration: 433,
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          467
        )
        .add(
          {
            targets: '.buy__content > p',
            duration: 433,
            delay: anime.stagger(33, { start: 500 }),
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          467
        )
    }

    // const downloadImage = () => {
    //   const form: HTMLFormElement = document.querySelector(
    //     '.form-leaderboard__form'
    //   )
    //   const btn = form.querySelector('button')
    //   if (form.checkValidity()) {
    //     sharingScore.value && sharingScore.value.download()
    //   } else {
    //     btn.click()
    //   }
    // }

    const runAnim = () => {
      if (store.getters['global/getOrientation']) {
        !isThanks.value && onEnter()
      }
    }

    onActivated(async () => {
      Array.from(
        document.querySelectorAll(
          '.submission__header, .form-leaderboard__desc, .form-leaderboard__form, .t-form > div, .submission__header > p'
        )
      ).forEach(c => {
        ;(c as HTMLElement).style.display = ''
        ;(c as HTMLElement).style.opacity = ''
      })

      form.isSend = false
      form.validate = false
      form.firstname = ''
      form.name = ''
      form.username = ''
      form.birthday = ''
      form.state = ''
      form.email = ''
      form.phone = ''
      form.rules = false
      form.newsletter = false
      form.birthdayValidation = false
      form.phoneValidation = false
      form.emailValidation = false
      form.already_entered = false
      form.recaptcha = null

      document.body.style.background = '#ffecd2'
      isThanks.value = false
      isLeaderboard.value = false

      if (check)
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'sweepstakes-form'
        })

      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: true
      })

      anime.set('.fake-bg', {
        backgroundColor: '#ffecd2'
      })

      unwatch = watchResize((w, h, isLandscape) => {
        runAnim()
      })

      runAnim()
    })

    onDeactivated(() => {
      document.body.style.background = ''
      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: false
      })
      unwatch()
    })

    const goThankYouNoSubmit = async () => {
      closePopinAlreadyEnter()
      await timeout(300)
      onSubmitDone()
    }

    const openPopinAlreadyEnter = async () => {
      await timeout(100)
      console.log('set popin open')
      popinOpen.value = true
    }

    const closePopinAlreadyEnter = async () => {
      popinOpen.value = false
      await timeout(500)
      form.already_entered = false
    }

    return {
      t,
      ...toRefs(form),
      popinOpen,
      onInputFocus,
      wordBanned,
      sendForm,
      onInputBlur,
      validateForm,
      checkForm,
      checkFormLeaderboard,
      goThankYouNoSubmit,
      openPopinAlreadyEnter,
      closePopinAlreadyEnter,
      isThanks,
      isLeaderboard,
      leaderboard,
      score,
      rank,
      allStates,
      // sharingScore,
      // downloadImage,
      scoreRankEl
    }
  }
}
</script>

<style lang="scss" scoped>
.submission {
  // background-color: color(pale-white);
  min-height: 100%;
  overflow: hidden;
  display: flex;

  &.no-score {
    .submission__header {
      margin-top: 40px;
      margin-bottom: 28px;

      @include respond-to(xs) {
        margin-top: 28px;
        margin-bottom: 20px;
      }

      .submission__thanks {
        margin-top: 60px;
        margin-bottom: 30px;

        @include respond-to(xs) {
          margin-top: 0px;
          margin-bottom: 20px;
        }

        .thanks-check {
          width: 112px;
          height: 112px;
        }
      }
    }
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
  }

  .submission__header {
    margin-top: 20px;
    margin-bottom: 17px;
    text-align: center;

    .separator {
      width: 100%;
      height: 1px;
      background: color(border);
      margin-top: 10px;
    }
  }

  .submission__thanks {
    position: relative;
    overflow: hidden;

    .thanks-check {
      position: relative;
      width: 97px;
      height: 97px;
      margin: 0 auto;

      .check-circle {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid rgba(color(dark-red), 0.2);
      }
      svg {
        filter: drop-shadow(0px 5px 0px #fff);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
          stroke-dasharray: 73;
        }
      }
    }

    .thanks-title {
      font-family: font(heading);
      font-weight: bold;
      font-size: 25px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-shadow: 0px 2px 0px #ffffff;
      color: #9d1d29;
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }

  .score-rank {
    margin-bottom: 12px;
  }

  .submission__welcoming,
  .submission__infos {
    color: color(red);
    font-size: 11px;
    margin: 0px;
    margin-bottom: 2px;
  }

  .submission__us {
    font-size: 11px;
    color: color(light-red);
  }

  .submission__form-leaderboard {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    margin: 0px 17px;

    .error {
      padding-top: 10px;
      padding-left: 35px;
      color: #ff0800;
      font-weight: bold;
      pointer-events: none;
    }

    .form-leaderboard__desc {
      margin-top: 20px;
      font-size: 11px;
      text-align: center;
      text-transform: uppercase;

      color: #9d1d29;
    }

    .form-leaderboard__form {
      margin-top: 5px;
      position: relative;
      padding-right: 70px;

      input {
        @include reset-input();
        border: 2px solid #d8635f;
        border-radius: 29px;
        background-color: #fff;
        padding: 14px 33px;
        width: 100%;
        padding-right: 60px;
        font-family: font(heading);
        font-size: 25px;
        color: #d8635f;
        text-transform: uppercase;

        &::placeholder {
          opacity: 0.2;
          color: #d8635f;
        }
      }

      button {
        @include reset-input();
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 127px;
        border-radius: 45px;
        background-color: #d8635f;
        font-family: font(heading);

        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.9px;
        text-transform: uppercase;

        color: #ffffff;

        text-shadow: 0.9px 0.9px 0px #af2135;

        &:disabled {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .form-leaderboard__btns {
      display: flex;
      justify-content: center;
      margin-top: 26px;

      .common-button:first-child {
        margin-right: 19px;
      }
    }
  }

  .sharing-box {
    position: absolute;
    z-index: -2;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    overflow: hidden;
  }

  .buy {
    position: relative;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-top: auto;

    .buy__bg,
    .buy__front {
      position: absolute;
      top: 0px;
      left: -16px;
      right: -16px;
      bottom: -16px;
      z-index: -1;

      img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        img {
          object-position: top center;
        }
      }
    }

    .buy__bg {
      top: -260px;
      bottom: auto;
      height: 355px;

      img {
        object-position: 20% 100%;
        opacity: 0.5;
      }
    }
    .buy__front {
      top: 20px;

      img {
        object-position: 10% 0%;
      }
    }

    .buy__content {
      text-align: center;
      color: #fff;
      margin-top: 60px;

      .main {
        font-size: 15px;
        text-transform: uppercase;
        max-width: 220px;
        margin: 0 auto;
        margin-bottom: 0px;
      }
      .snd {
        font-size: 14px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 14px;
        max-width: 300px;
      }
    }
  }
  .already-entered {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(216, 99, 95, 0);
    display: flex;
    flex-direction: column;
    padding: 0 35px;
    align-items: center;
    justify-content: center;
    transition: background 0.5s $ease-out-sine 0.3s;
    &-wrapper {
      position: relative;
      background: linear-gradient(0deg, #ffecd2 0%, #ffffff 100%), #ffffff;
      border-radius: 15px;
      padding: 65px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      opacity: 0;
      transition: opacity 0.3s $ease-out-sine;

      .submission__welcoming {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16.8px;
      }

      button {
        margin-top: 25px;
      }
      .close {
        margin-top: 0;
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
    &.open {
      background: rgba(216, 99, 95, 0.7);
      transition: background 0.5s $ease-out-sine;
      .already-entered-wrapper {
        opacity: 1;
        transition: opacity 0.3s $ease-out-sine 0.3s;
      }
    }
  }
}
</style>
