<template>
  <header
    class="game-header"
    :class="{ 'logo-left': logoLeftAligned, borders: borders }"
  >
    <div class="game-header__inner">
      <router-link to="/game-intro">
        <logo v-if="logo" class="logo" />
      </router-link>
      <main-menu></main-menu>
    </div>
    <div v-if="borders" class="borders" />

    <!-- for ios inertia -->
    <div v-if="borders" class="fake-borders" />
    <div v-if="borders" class="fake-bg" />

    <div v-if="!borders" class="bottom-line" />
  </header>
</template>

<script>
import Logo from '@/assets/svg/high-west-logo.svg'
import MainMenu from '@/components/MainMenu.vue'

export default {
  components: {
    logo: Logo,
    MainMenu
  },
  props: {
    logo: {
      type: Boolean,
      default: false
    },
    logoLeftAligned: {
      type: Boolean,
      default: false
    },
    borders: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.game-header {
  height: 52px;
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  z-index: 5;

  .game-header__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    a {
      position: relative;
      z-index: 4;
      font-size: 0px;
    }
  }

  &.borders {
    position: fixed;

    .borders {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid color(border);
      border-radius: 15px 15px 0 0;
    }

    .fake-borders {
      position: absolute;
      top: 20px;
      left: 0px;
      width: 100%;
      height: 50vh;
      border: solid color(border);
      border-width: 0px 1px;
      pointer-events: none;
    }

    .fake-bg {
      position: fixed;
      display: block;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 67px;
      background-color: color(pale-white);
      z-index: -2;
      pointer-events: none;

      @include respond-to(xs) {
        height: 50px;
      }
    }
  }

  .bottom-line {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;

    border-bottom: 1px solid color(border);
  }

  &.logo-left {
    .game-header__inner {
      justify-content: flex-start;
    }
    .logo {
      margin-left: 20px;
      height: 19px;

      color: color(dark-red);

      @include respond-to(xs) {
        height: 16px;
      }
    }
  }

  @include respond-to(xs) {
    top: 12px;
    right: 12px;
    left: 12px;
    height: 38px;
  }

  svg {
    width: auto;
    height: 33px;

    @include respond-to(xs) {
      height: 23px;
    }
  }
}
</style>
