
import { checkPage } from '@/hooks/global'
import LeaderboardTable from '@/components/LeaderBoardTable.vue'
import SiteHeader from '@/components/GameHeader.vue'

import { inject, onActivated, onDeactivated, ref } from 'vue'
import store from '@/store'

import anime from 'animejs/lib/anime.es.js'
import CommonButton from '@/components/CommonButton.vue'

export default {
  name: 'Leaderboard',
  components: {
    CommonButton,
    LeaderboardTable,
    SiteHeader
  },
  setup() {
    checkPage()
    const t = inject('$t')
    const SCORE_API = process.env.VUE_APP_SCORE_API
    const hasTable = ref(false)

    const best = ref(null)

    const getBest = async () => {
      const rawResponse = await fetch(`${SCORE_API}score/best`)
      const data = await rawResponse.json()

      best.value = data

      hasTable.value = true

      anime({
        targets: '.buy',
        delay: 500,
        duration: 500,
        easing: 'easeOutSine',
        opacity: 1
      })
    }

    const share = () => {
      if (!navigator.share) {
        return
      }

      console.log('share')

      navigator.share({
        title: document.title,
        url: window.location.href
      })
    }

    onActivated(() => {
      // onEnter()
    })

    onDeactivated(() => {
      hasTable.value = false
      document.body.style.background = ''
      anime.set('.fake-borders', {
        opacity: ''
      })
      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: false
      })

      best.value = false
    })

    const onEnter = (el, done) => {
      hasTable.value = false
      window.scrollTo(0, 0)
      getBest()
      anime.set('.buy', {
        opacity: 0
      })
      anime.set('.fake-bg', {
        backgroundColor: ''
      })
      anime({
        targets: el,
        duration: 330,
        easing: 'linear',
        opacity: [0, 1],
        complete: () => {
          document.body.style.background = 'rgb(252, 211, 159)'
          store.commit('global/toggleFrontGridVisibility', {
            isFrontGridHidden: true
          })

          anime.set('.fake-bg', {
            backgroundColor: 'rgb(252, 211, 159)'
          })

          anime.set('.fake-borders', {
            opacity: 0
          })
          done()
        }
      })
    }
    const onLeave = (el, done) => {
      anime({
        targets: '.fake-bg, .fake-borders',
        duration: 200,
        easing: 'linear',
        opacity: 0
      })
      anime({
        targets: el,
        duration: 200,
        easing: 'linear',
        opacity: 0,
        complete: () => {
          anime.set('.fake-borders', {
            opacity: ''
          })
          anime.set('.fake-bg', {
            opacity: ''
          })
          best.value = false
          done()
        }
      })
    }

    return {
      t,
      hasTable,
      share,
      onEnter,
      onLeave,
      best
    }
  }
}
