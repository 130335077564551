
import {
  checkPage,
  hasFakeScore,
  isDebugMode,
  watchResize
} from '@/hooks/global'
import {
  reactive,
  toRefs,
  inject,
  onMounted,
  onActivated,
  onDeactivated,
  ref,
  nextTick,
  computed
} from 'vue'
import store from '@/store'
import SiteHeader from '@/components/GameHeader.vue'
import ScoreRank from '@/components/ScoreRank.vue'
import CommonButton from '@/components/CommonButton.vue'
// import SharingScore from '@/components/SharingScore.vue'

import moment from 'moment'

import banWord from '@/utils/WordBan'

import LeaderboardTable from '@/components/LeaderBoardTable.vue'

import { load } from 'recaptcha-v3'
import anime from 'animejs/lib/anime.es.js'
import { useGtm } from 'vue-gtm'
import timeout from '@/utils/timeout'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
function validateUSPhonenumber(phone) {
  const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  return re.test(String(phone).toLowerCase())
}

export default {
  components: {
    SiteHeader,
    ScoreRank,
    CommonButton,
    LeaderboardTable
    // SharingScore
  },
  setup() {
    const gtm = useGtm()
    const check = checkPage()
    const t = inject('$t')
    const isDebug = ref(isDebugMode())
    const hasScore = ref(hasFakeScore())

    if (hasScore.value) {
      store.commit('game/setScore', { score: hasScore.value })
    }

    const SCORE_API = process.env.VUE_APP_SCORE_API
    const REGISTRATION_API = process.env.VUE_APP_REGISTRATION_API
    const REGISTRATION_TOKEN = process.env.VUE_APP_REGISTRATION_TOKEN

    const wordBanned = ref(false)
    const sendForm = ref(false)
    const isThanks = ref(false)
    const isLeaderboard = ref(false)
    const isSubmitting = ref(false)
    const popinOpen = ref(false)
    const leaderboard = ref(null)
    // const sharingScore = ref(null)
    const scoreRankEl = ref(null)

    const allStates = ref({
      ALABAMA: 'AL',
      ALASKA: 'AK',
      ARIZONA: 'AZ',
      ARKANSAS: 'AR',
      CALIFORNIA: 'CA',
      COLORADO: 'CO',
      CONNECTICUT: 'CT',
      'DISTRICT OF COLUMBIA (D.C.)': 'DC',
      DELAWARE: 'DE',
      FLORIDA: 'FL',
      GEORGIA: 'GA',
      HAWAII: 'HI',
      IDAHO: 'ID',
      ILLINOIS: 'IL',
      INDIANA: 'IN',
      IOWA: 'IA',
      KANSAS: 'KS',
      KENTUCKY: 'KY',
      LOUISIANA: 'LA',
      MAINE: 'ME',
      MARYLAND: 'MD',
      MASSACHUSETTS: 'MA',
      MICHIGAN: 'MI',
      MINNESOTA: 'MN',
      MISSISSIPPI: 'MS',
      MISSOURI: 'MO',
      MONTANA: 'MT',
      NEBRASKA: 'NE',
      NEVADA: 'NV',
      'NEW HAMPSHIRE': 'NH',
      'NEW JERSEY': 'NJ',
      'NEW MEXICO': 'NM',
      'NEW YORK': 'NY',
      'NORTH CAROLINA': 'NC',
      'NORTH DAKOTA': 'ND',
      OHIO: 'OH',
      OKLAHOMA: 'OK',
      OREGON: 'OR',
      PENNSYLVANIA: 'PA',
      'RHODE ISLAND': 'RI',
      'SOUTH CAROLINA': 'SC',
      'SOUTH DAKOTA': 'SD',
      TENNESSEE: 'TN',
      TEXAS: 'TX',
      UTAH: 'UT',
      VERMONT: 'VT',
      VIRGINIA: 'VA',
      WASHINGTON: 'WA',
      'WEST VIRGINIA': 'WV',
      WISCONSIN: 'WI',
      WYOMING: 'WY'
    })

    const score = computed(() => store.getters['game/score'])

    const rank = computed(() => {
      return scoreRankEl.value.simpleRank
    })

    let unwatch = null

    const form = reactive({
      isSend: false,
      validate: false,
      firstname: '',
      name: '',
      username: '',
      birthday: '',
      state: '',
      email: '',
      phone: '',
      rules: false,
      newsletter: false,
      birthdayValidation: false,
      phoneValidation: false,
      emailValidation: false,
      already_entered: false,
      recaptcha: null
    })

    let tlEnter = null

    const onInputFocus = (ev, type) => {
      const parent = ev.target.parentNode
      parent.classList.add('submission-form-block--filled')
    }

    const onInputBlur = ev => {
      if (ev.target.value.trim() === '') {
        const parent = ev.target.parentNode
        parent.classList.remove('submission-form-block--filled')
      }
    }

    const isOver21 = () => {
      const birthdate = moment(`${form.birthday}`, 'YYYY-MM-DD')
      if (!birthdate.isValid()) return

      return birthdate.isBefore(moment().subtract(21, 'years'))
    }

    const validateForm = () => {
      form.already_entered = false
      form.emailValidation = validateEmail(form.email)
      form.phoneValidation =
        validateUSPhonenumber(form.phone) || form.phone.length === 0
      form.birthdayValidation = isOver21()
      if (
        form.firstname.length > 0 &&
        form.name.length > 0 &&
        form.state.length > 0 &&
        form.email.length > 0 &&
        form.rules
      ) {
        form.validate = form.emailValidation && form.birthdayValidation
        if (form.phone.length > 0) {
          form.validate = form.phoneValidation
        } else form.phoneValidation = true
      } else {
        form.validate = false
      }
    }

    const checkForm = async () => {
      if (isSubmitting.value) return
      isSubmitting.value = true
      form.isSend = true

      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'submitform',
        eventLabel: '',
        eventValue: score.value ? 0 : 1,
        eventCategory: 'cta'
      })

      validateForm()
      if (form.validate) {
        const [year, month, day] = form.birthday.split('-')

        let params = {
          firstname: form.firstname,
          lastname: form.name,
          state: form.state,
          day,
          month,
          year,
          email: form.email,
          phonenumber: form.phone.length < 1 ? '' : form.phone,
          highwestwhiskeyclub: form.newsletter,
          accessibleregistration: score.value ? false : true,
          score: score.value ? score.value : 0
        }

        if (!isDebug.value) {
          sendForm.value = true
          const rawResponse = await fetch(REGISTRATION_API, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${REGISTRATION_TOKEN}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
          })

          const { errors, status } = await rawResponse.json()
          let logErrors = ''
          if (errors) {
            for (const error of errors) {
              logErrors += error + ' '
              console.log('error')
              switch (error) {
                case 'state':
                  form.state = ''
                  break
                case 'phonenumber':
                  form.phoneValidation = false
                  break
                default:
                  form.validate = false
              }
            }
            sendForm.value = false
            isSubmitting.value = false
          } else if (status === 'success') {
            onSubmitDone()
          } else {
            form.already_entered = true
            form.validate = false
            isSubmitting.value = false
            sendForm.value = false
            openPopinAlreadyEnter()
          }
          gtm.trackEvent({
            event: 'eventPush',
            eventAction: 'success',
            eventLabel: 'error message',
            eventValue: errors ? logErrors : 1,
            eventCategory: 'register'
          })
        } else {
          onSubmitDone()
        }
      } else {
        window.scrollTo(0, 0)
        isSubmitting.value = false
      }
    }

    const checkFormLeaderboard = async () => {
      if (isSubmitting.value) return

      gtm.trackEvent({
        event: 'eventPush',
        eventAction: '',
        eventLabel: '',
        eventValue: '',
        eventCategory: 'cta'
      })

      wordBanned.value = banWord(form.username.toLowerCase())
      if (wordBanned.value) {
        gtm.trackEvent({
          event: 'eventPush',
          eventAction: 'profanity',
          eventLabel: '',
          eventValue: '',
          eventCategory: 'profanity'
        })
        return
      }

      isSubmitting.value = true
      sendForm.value = true

      const recaptcha = await load(process.env.VUE_APP_SCORE_API_RECAPTCHA, {
        autoHideBadge: true
      })
      const token = await recaptcha.execute('submit')

      let params = {
        score: `${score.value}`,
        username: form.username,
        'g-recaptcha-response': token
      }
      let body = new URLSearchParams(params)

      try {
        const rawResponse = await fetch(`${SCORE_API}score/save`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body
        })

        const data = await rawResponse.json()

        leaderboard.value = data
        onSubmitLeaderBoardDone()
        isSubmitting.value = false
      } catch (error) {
        isSubmitting.value = false
      }
      sendForm.value = false
    }

    const onEnter = () => {
      window.scrollTo(0, 0)
      let tlEnter = anime.timeline({
        complete: () => {
          unwatch()
        }
      })

      tlEnter.add({
        targets: '.t-form > div',
        duration: 333,
        delay: anime.stagger(88, { start: 300 }),
        opacity: [0, 1],
        easing: 'cubicBezier(0.33, 0, 0.67, 1)'
      })
    }

    const onSubmitLeaderBoardDone = () => {
      gtm.trackEvent({
        event: 'vpv',
        VPV: 'confirmation-ame'
      })
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement

      const tl = anime.timeline({
        complete: () => {
          isThanks.value = false
          isLeaderboard.value = true

          anime.set(
            '.submission__header, .form-leaderboard__desc, .form-leaderboard__form',
            {
              display: 'none'
            }
          )
        }
      })
      tl.add(
        {
          targets: scrollElement,
          duration: 400,
          scrollTop: 0,
          easing: 'cubicBezier(0.33, 0, 0.67, 1)'
        },
        0
      ).add({
        targets:
          '.submission__header, .form-leaderboard__desc, .form-leaderboard__form',
        duration: 667,
        opacity: 0,
        easing: 'cubicBezier(0.43, 0, 0.78, 1)'
      })
    }

    const onSubmitDone = () => {
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement

      if (score.value) {
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'leaderboard-form'
        })
      } else {
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'confirmation-ame'
        })
      }
      const tl = anime.timeline({
        delay: 1000,
        complete: () => {
          sendForm.value = false
          isThanks.value = true
          isSubmitting.value = false
          nextTick(onThanks)
        }
      })
      tl.add(
        {
          targets: scrollElement,
          duration: 400,
          scrollTop: 0,
          easing: 'cubicBezier(0.33, 0, 0.67, 1)'
        },
        0
      )
        .add(
          {
            targets: 'body, .game-header .fake-bg',
            duration: 400,
            backgroundColor: '#fcd39f',
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          400
        )
        .add(
          {
            targets: '.t-form > div, .submission__header > p',
            duration: 333,
            delay: anime.stagger(88, { direction: 'reverse' }),
            opacity: 0,
            display: 'none',
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            complete: anim => {
              anim.animatables.forEach(element => {
                element.target.style.display = 'none'
              })
            }
          },
          400
        )
    }

    const onThanks = () => {
      const tl = anime.timeline()
      tl.add(
        {
          targets: '.submission__thanks',
          duration: 567,
          height: [0, 158],
          easing: 'cubicBezier(0.7, 0, 0.34, 1)'
        },
        0
      )
        .add(
          {
            targets: '.submission__header .separator',
            duration: 567,
            opacity: [0, 1],
            translateY: [-20, 0],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          0
        )
        .add(
          {
            targets: '.buy__bg',
            duration: 1967,
            translateY: ['80%', 0],
            scale: [1.4, 1],
            opacity: [0, 1],
            easing: 'cubicBezier(0.68, 0.08, 0.27, 1)'
          },
          0
        )
        .add(
          {
            targets: '.buy__front',
            duration: 1967,
            translateY: ['120%', 0],
            scale: [1.4, 1],
            opacity: [0, 1],
            easing: 'cubicBezier(0.68, 0.08, 0.27, 1)'
          },
          0
        )
        .add(
          {
            targets: '.submission__header .check-circle',
            duration: 636,
            scale: [0, 1],
            easing: 'cubicBezier(0.66, 0, 0.34, 1)'
          },
          233
        )
        .add(
          {
            targets: '.submission__header .thanks-check path',
            duration: 617,
            strokeDashoffset: [73, 146],
            easing: 'cubicBezier(0.76, 0, 0.26, 1)'
          },
          233
        )
        .add(
          {
            targets: '.submission__header .thanks-title',
            duration: 533,
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          367
        )
        .add(
          {
            targets: '.form-leaderboard__desc',
            duration: 467,
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          400
        )
        .add(
          {
            targets: '.form-leaderboard__form',
            duration: 433,
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          467
        )
        .add(
          {
            targets: '.buy__content > p',
            duration: 433,
            delay: anime.stagger(33, { start: 500 }),
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          467
        )
    }

    // const downloadImage = () => {
    //   const form: HTMLFormElement = document.querySelector(
    //     '.form-leaderboard__form'
    //   )
    //   const btn = form.querySelector('button')
    //   if (form.checkValidity()) {
    //     sharingScore.value && sharingScore.value.download()
    //   } else {
    //     btn.click()
    //   }
    // }

    const runAnim = () => {
      if (store.getters['global/getOrientation']) {
        !isThanks.value && onEnter()
      }
    }

    onActivated(async () => {
      Array.from(
        document.querySelectorAll(
          '.submission__header, .form-leaderboard__desc, .form-leaderboard__form, .t-form > div, .submission__header > p'
        )
      ).forEach(c => {
        ;(c as HTMLElement).style.display = ''
        ;(c as HTMLElement).style.opacity = ''
      })

      form.isSend = false
      form.validate = false
      form.firstname = ''
      form.name = ''
      form.username = ''
      form.birthday = ''
      form.state = ''
      form.email = ''
      form.phone = ''
      form.rules = false
      form.newsletter = false
      form.birthdayValidation = false
      form.phoneValidation = false
      form.emailValidation = false
      form.already_entered = false
      form.recaptcha = null

      document.body.style.background = '#ffecd2'
      isThanks.value = false
      isLeaderboard.value = false

      if (check)
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'sweepstakes-form'
        })

      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: true
      })

      anime.set('.fake-bg', {
        backgroundColor: '#ffecd2'
      })

      unwatch = watchResize((w, h, isLandscape) => {
        runAnim()
      })

      runAnim()
    })

    onDeactivated(() => {
      document.body.style.background = ''
      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: false
      })
      unwatch()
    })

    const goThankYouNoSubmit = async () => {
      closePopinAlreadyEnter()
      await timeout(300)
      onSubmitDone()
    }

    const openPopinAlreadyEnter = async () => {
      await timeout(100)
      console.log('set popin open')
      popinOpen.value = true
    }

    const closePopinAlreadyEnter = async () => {
      popinOpen.value = false
      await timeout(500)
      form.already_entered = false
    }

    return {
      t,
      ...toRefs(form),
      popinOpen,
      onInputFocus,
      wordBanned,
      sendForm,
      onInputBlur,
      validateForm,
      checkForm,
      checkFormLeaderboard,
      goThankYouNoSubmit,
      openPopinAlreadyEnter,
      closePopinAlreadyEnter,
      isThanks,
      isLeaderboard,
      leaderboard,
      score,
      rank,
      allStates,
      // sharingScore,
      // downloadImage,
      scoreRankEl
    }
  }
}
