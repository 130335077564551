<template>
  <div class="main-menu" :class="{ show: isOpen }">
    <button class="t-btn main-menu-button" @click="toggleButton">
      <svg
        ref="bars"
        class="bars"
        width="24"
        height="14"
        viewBox="0 0 24 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="22.838"
          y1="1.498"
          x2="1.22"
          y2="1.498"
          stroke="#510020"
          stroke-linecap="round"
          stroke-linejoin="bevel"
        />
        <line
          x1="22.838"
          y1="7.498"
          x2="1.22"
          y2="7.498"
          stroke="#510020"
          stroke-linecap="round"
          stroke-linejoin="bevel"
        />
        <line
          x1="22.838"
          y1="13.498"
          x2="1.22"
          y2="13.498"
          stroke="#510020"
          stroke-linecap="round"
          stroke-linejoin="bevel"
        />
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
import {
  computed,
  nextTick,
  onActivated,
  onDeactivated,
  onMounted,
  ref
} from 'vue'
import anime from 'animejs/lib/anime.es.js'
import store from '@/store'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'

export default {
  setup() {
    const isOpen = ref(false)
    const bars = ref(null)
    const howler = useHowler()

    const sound = howler.$getSound('wouf', false, {
      srcs: [FX_URL + 'popup.mp3'],
      preload: true,
      html5: false,
      preloadCallback: () => {}
    })

    const toggleButton = () => {
      nextTick(openMenu)

      sound.play()
    }

    onMounted(() => {
      onEnter()
    })

    onActivated(() => {
      onEnter()
    })

    onDeactivated(() => {
      // closeMenu()
      isOpen.value = false
    })

    const onEnter = (delay = 500) => {
      anime({
        targets: '.bars line',
        duration: 600,
        scaleX: [0, 1],
        delay: anime.stagger(133, { direction: 'reverse', start: delay }),
        easing: 'cubicBezier(0.67, 0, 0.25, 1)'
      })
    }

    const openMenu = () => {
      if (store.getters['global/menuOpen']) return
      store.commit('global/setMenu', true)

      const tl = anime.timeline()
      tl.add(
        {
          targets: '.circle',
          duration: 367,
          easing: 'cubicBezier(0.4, 0, 0.83, 1)',
          translateX: ['-50%', '-50%'],
          translateY: ['-50%', '-50%'],
          keyframes: [
            { opacity: 1, duration: 0 },
            {
              opacity: 0,
              duration: 367,
              easing: 'cubicBezier(0.33, 0, 0.67, 1)'
            }
          ],
          scale: [0, 1]
        },
        33
      ).add(
        {
          targets: '.cross',
          duration: 120,
          easing: 'cubicBezier(0.4, 0, 0.83, 1)',
          scale: [0, 1]
        },
        66
      )
    }

    return {
      isOpen,
      toggleButton,
      bars
    }
  }
}
</script>

<style lang="scss" scoped>
.main-menu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 52px;
  z-index: 3;

  &-button {
    width: 62px;
    height: 52px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    transition: 0.25s ease;

    .circle {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid rgba(color(dark-red), 0.4);
      transform-origin: center;
      opacity: 0;
    }

    @include respond-to(xs) {
      // width: 40px;
      height: 38px;
    }

    svg {
      width: 23px;
      height: 12px;

      line {
        transform-origin: right;
      }
    }
  }
}
</style>
