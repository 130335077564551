
import {
  computed,
  inject,
  nextTick,
  onActivated,
  onDeactivated,
  onMounted,
  ref
} from 'vue'
import store from '@/store'
import anime from 'animejs/lib/anime.es.js'

import CommonButton from '@/components/CommonButton.vue'
import { watchResize } from '@/hooks/global'
import { useGtm } from 'vue-gtm'
export default {
  name: 'EndScore',
  components: {
    'common-button': CommonButton
  },
  setup() {
    const t = inject('$t')
    const gtm = useGtm()
    const score = computed(() => store.getters['game/score'] || 0)
    const nbEntries = computed(() => store.getters['game/entries'])
    const entries = computed(() => {
      const nb = nbEntries.value
      const multiples = nb > 1
      const output = `${nb} entr${multiples ? 'ies' : 'y'}`
      return output
    })
    const showBtns = ref(false)
    let tl = null
    let unwatch = null

    onActivated(() => {
      unwatch = watchResize((w, h, isLandscape) => {
        runAnim()
      })
      runAnim()
    })

    onDeactivated(() => {
      unwatch()
      showBtns.value = false
    })

    const runAnim = () => {
      if (store.getters['global/forceLandscape']) {
        nextTick(onEnter)
        showBtns.value = true
      } else {
        if (store.getters['global/getOrientation']) {
          showBtns.value = false
        } else {
          nextTick(onEnter)
          showBtns.value = true
        }
      }
    }

    const onEnter = () => {
      gtm.trackEvent({
        event: 'vpv',
        VPV: 'gameEnd'
      })
      if (score.value === 0) store.commit('game/setScore', { score: 1 })
      tl = anime.timeline({
        delay: 500
      })
      tl.add(
        {
          targets: '.end-score__bg',
          duration: 3000,
          easing: 'cubicBezier(0.10, 0.66, 0.20, 1.00)',
          scale: [1.15, 1],
          opacity: [0, 1]
        },
        0
      )
        .add(
          {
            targets: '.grid-front',
            duration: 567,
            opacity: [0, 1],
            easing: 'linear'
          },
          0
        )
        .add(
          {
            targets: '.score__label',
            duration: 1000,
            easing: 'cubicBezier(0, 0, 0.1, 1)',
            translateY: ['100%', 0]
          },
          0
        )
        .add(
          {
            targets: '.separator, .end-score__close',
            duration: 567,
            easing: 'linear',
            opacity: [0, 1]
          },
          0
        )
        .add(
          {
            targets: '.entries',
            duration: 1267,
            easing: 'cubicBezier(0.74, 0, 0.24, 1)',
            opacity: [0, 1]
          },
          167
        )
        .add(
          {
            targets: '.labels-btns .label-main',
            duration: 833,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            opacity: [0, 1]
          },
          1000
        )
        .add(
          {
            targets: '.labels-btns .label-second',
            duration: 833,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            opacity: [0, 1]
          },
          1300
        )
        .add(
          {
            targets: '.mention',
            duration: 833,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            opacity: [0, 1]
          },
          1300
        )
    }

    const exitFullScreen = () => {
      if (document.fullscreenElement) document.exitFullscreen()
    }

    const getMainSentence = () => {
      return (t as any)('Score.main-score')
        .split('{{XX}}')
        .join('<span>' + entries.value + '</span>')
    }

    return {
      score,
      entries,
      t,
      onEnter,
      showBtns,
      exitFullScreen,
      getMainSentence
    }
  }
}
