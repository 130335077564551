
import { checkPage } from '@/hooks/global'
import SiteHeader from '@/components/GameHeader.vue'
import VideoOverlay from '@/components/VideoOverlay.vue'
import HorseshoeDivider from '@/assets/svg/horseshoe-divider.svg'
import store from '@/store'
import { useGtm } from 'vue-gtm'

import { inject, onBeforeUnmount, onDeactivated, ref } from 'vue'
import anime from 'animejs/lib/anime.es.js'
import { clamp, map } from '@/webgl/math'

interface positions {
  quote?: DOMRect
  divider?: DOMRect
  bottom?: DOMRect
}

const IMGS = [
  {
    name: 'about-image-bottom.jpg',
    url: '/assets/images/about/about-image-bottom.jpg'
  },
  {
    name: 'about-video-thumbnail.png',
    url: '/assets/images/about/about-video-thumbnail.png'
  },
  {
    name: 'high-west-about.png',
    url: '/assets/images/about/high-west-about.png'
  },
  {
    name: 'high-west-bottle.png',
    url: '/assets/images/about/high-west-bottle.png'
  }
]

export default {
  name: 'About',

  components: {
    SiteHeader,
    HorseshoeDivider,
    VideoOverlay
  },

  setup() {
    const gtm = useGtm()
    const check = checkPage()

    const t = inject('$t')

    const scrollY = {
      dist: 0,
      current: 0
    }

    const positions: positions = {}

    let raf = null

    const loaded = ref(false)
    const videoOpen = ref(false)
    const about = ref(null)
    const quote = ref(null)
    const divider = ref(null)
    const bottom = ref(null)

    const openVideoOverlay = () => {
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'playvideo',
        eventLabel: 'Video Player',
        eventValue: '',
        eventCategory: 'about'
      })
      videoOpen.value = true
    }

    const closeVideoOverlay = () => {
      videoOpen.value = false
    }

    const onTick = () => {
      const vp = store.getters['global/getViewportSize']
      scrollY.current += (scrollY.dist - scrollY.current) * 0.25

      const aboutY = scrollY.current * 0.8
      const aboutOpacity = map(scrollY.current, 0, vp.height * 0.6, 1, 0)

      if (aboutOpacity > 0) {
        about.value.style.transform = `translate3d(-50%, ${aboutY}px, 0)`
        about.value.style.opacity = clamp(aboutOpacity, 0, 1)
      }

      const quoteOpacity = map(
        scrollY.current,
        positions.quote.top - vp.height * 0.9,
        positions.quote.top - vp.height * 0.6,
        0,
        1
      )
      quote.value.style.opacity = clamp(quoteOpacity, 0, 1)

      const dividerOpacity = map(
        scrollY.current,
        positions.divider.top - vp.height * 0.9,
        positions.divider.top - vp.height * 0.5,
        0,
        1
      )
      divider.value.style.opacity = clamp(dividerOpacity, 0, 1)

      const bottomOpacity = map(
        scrollY.current,
        positions.bottom.top - vp.height * 0.8,
        positions.bottom.top - vp.height * 0.6,
        0,
        1
      )
      bottom.value.style.opacity = clamp(bottomOpacity, 0, 1)

      raf = window.requestAnimationFrame(onTick)
    }

    const onScroll = () => {
      scrollY.dist = window.scrollY
    }

    const getPositions = () => {
      const quoteBB = quote.value.getBoundingClientRect()
      positions.quote = quoteBB
      const dividerBB = divider.value.getBoundingClientRect()
      positions.divider = dividerBB
      const bottomBB = bottom.value.getBoundingClientRect()
      positions.bottom = bottomBB
    }

    const loadAssets = async () => {
      return await Promise.all(
        IMGS.map(asset => {
          return new Promise(resolve => {
            const image = new Image()
            image.addEventListener('load', () => {
              resolve(image)
              console.log(asset.url, 'loaded')
            })
            image.src = asset.url
          })
        })
      )
    }

    const onEnter = async (el, done) => {
      if (check)
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'about'
        })
      window.scrollTo(0, 0)
      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: true
      })

      anime.set('.fake-bg', {
        backgroundColor: '#fcd39f'
      })

      window.addEventListener('scroll', onScroll)
      onScroll()

      await loadAssets()
      loaded.value = true
      done()
      setTimeout(() => {
        document.body.style.background = '#fcd39f'
        getPositions()
        onTick()
      }, 100)
    }

    // onMounted(async () => {

    // })

    const destroy = () => {
      document.body.style.background = ''
      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: false
      })

      anime.set('.fake-bg', {
        backgroundColor: ''
      })

      window.removeEventListener('scroll', onScroll)
      window.cancelAnimationFrame(raf)
    }

    onDeactivated(() => {
      destroy()
    })

    onBeforeUnmount(() => {
      destroy()
    })

    return {
      videoOpen,
      openVideoOverlay,
      closeVideoOverlay,
      about,
      divider,
      quote,
      bottom,
      loaded,
      onEnter,
      t
    }
  }
}
