<template>
  <div class="end-score">
    <div class="end-score__bg">
      <img src="@/assets/images/score-bg.jpg" alt="" />
    </div>
    <div class="end-score__close">
      <router-link to="/game-intro">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cross"
        >
          <path
            d="M1.085 1.211L18.763 18.89M18.763 1.211L1.086 18.89"
            stroke="#F28E6C"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="bevel"
          />
        </svg>
      </router-link>
    </div>
    <div class="end-score__inner">
      <div class="score">
        <span class="score__mask">
          <span class="score__label">{{ score }}</span>
        </span>
      </div>

      <div class="entries">
        <div class="entries__borders" />
        <span>{{ entries }}</span>
      </div>

      <div class="separator" />

      <div class="labels-btns">
        <p class="label-main" v-html="getMainSentence()" />
        <p class="label-second">{{ t('Score.main2-score') }}</p>
      </div>

      <div class="btns">
        <common-button
          v-if="showBtns"
          :appear="1400"
          :label="t('Score.enter-score-cta')"
          to="/submission"
          :tracking="{
            event: 'eventPush',
            eventAction: 'entersweepstakes',
            eventLabel: '',
            eventValue: '',
            eventCategory: 'game'
          }"
          @click="exitFullScreen"
        />
        <common-button
          v-if="showBtns"
          :appear="1600"
          :label="t('Score.replay-score-cta')"
          to="/game"
          outline
          icon="replay"
          color="red"
          :tracking="{
            event: 'eventPush',
            eventAction: 'replay',
            eventLabel: '',
            eventValue: '',
            eventCategory: 'game'
          }"
        />
      </div>
      <p class="mention" v-html="t('About.mention')" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  inject,
  nextTick,
  onActivated,
  onDeactivated,
  onMounted,
  ref
} from 'vue'
import store from '@/store'
import anime from 'animejs/lib/anime.es.js'

import CommonButton from '@/components/CommonButton.vue'
import { watchResize } from '@/hooks/global'
import { useGtm } from 'vue-gtm'
export default {
  name: 'EndScore',
  components: {
    'common-button': CommonButton
  },
  setup() {
    const t = inject('$t')
    const gtm = useGtm()
    const score = computed(() => store.getters['game/score'] || 0)
    const nbEntries = computed(() => store.getters['game/entries'])
    const entries = computed(() => {
      const nb = nbEntries.value
      const multiples = nb > 1
      const output = `${nb} entr${multiples ? 'ies' : 'y'}`
      return output
    })
    const showBtns = ref(false)
    let tl = null
    let unwatch = null

    onActivated(() => {
      unwatch = watchResize((w, h, isLandscape) => {
        runAnim()
      })
      runAnim()
    })

    onDeactivated(() => {
      unwatch()
      showBtns.value = false
    })

    const runAnim = () => {
      if (store.getters['global/forceLandscape']) {
        nextTick(onEnter)
        showBtns.value = true
      } else {
        if (store.getters['global/getOrientation']) {
          showBtns.value = false
        } else {
          nextTick(onEnter)
          showBtns.value = true
        }
      }
    }

    const onEnter = () => {
      gtm.trackEvent({
        event: 'vpv',
        VPV: 'gameEnd'
      })
      if (score.value === 0) store.commit('game/setScore', { score: 1 })
      tl = anime.timeline({
        delay: 500
      })
      tl.add(
        {
          targets: '.end-score__bg',
          duration: 3000,
          easing: 'cubicBezier(0.10, 0.66, 0.20, 1.00)',
          scale: [1.15, 1],
          opacity: [0, 1]
        },
        0
      )
        .add(
          {
            targets: '.grid-front',
            duration: 567,
            opacity: [0, 1],
            easing: 'linear'
          },
          0
        )
        .add(
          {
            targets: '.score__label',
            duration: 1000,
            easing: 'cubicBezier(0, 0, 0.1, 1)',
            translateY: ['100%', 0]
          },
          0
        )
        .add(
          {
            targets: '.separator, .end-score__close',
            duration: 567,
            easing: 'linear',
            opacity: [0, 1]
          },
          0
        )
        .add(
          {
            targets: '.entries',
            duration: 1267,
            easing: 'cubicBezier(0.74, 0, 0.24, 1)',
            opacity: [0, 1]
          },
          167
        )
        .add(
          {
            targets: '.labels-btns .label-main',
            duration: 833,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            opacity: [0, 1]
          },
          1000
        )
        .add(
          {
            targets: '.labels-btns .label-second',
            duration: 833,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            opacity: [0, 1]
          },
          1300
        )
        .add(
          {
            targets: '.mention',
            duration: 833,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)',
            opacity: [0, 1]
          },
          1300
        )
    }

    const exitFullScreen = () => {
      if (document.fullscreenElement) document.exitFullscreen()
    }

    const getMainSentence = () => {
      return (t as any)('Score.main-score')
        .split('{{XX}}')
        .join('<span>' + entries.value + '</span>')
    }

    return {
      score,
      entries,
      t,
      onEnter,
      showBtns,
      exitFullScreen,
      getMainSentence
    }
  }
}
</script>

<style lang="scss" scoped>
.end-score {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  padding: 15px;

  .end-score__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center bottom;
      transform: scale(1.1);
      transform-origin: bottom;
      opacity: 0.8;
    }
  }

  .end-score__close {
    position: absolute;
    top: 32px;
    right: 37px;
    z-index: 5;
  }

  .end-score__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    flex-direction: column;
    position: relative;
    height: 100%;

    .score {
      font-size: 63px;
      text-align: center;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      color: color(light-red);
      text-shadow: 0px 2.24358px 0px #ffffff;
      margin-bottom: 10px;

      .score__label {
        display: block;
      }

      .score__mask {
        overflow: hidden;
        line-height: 64px;
        display: block;
      }

      @include respond-to-height(xxs) {
        font-size: 50px;
        margin-bottom: 5px;
      }
    }

    .entries {
      font-size: 13.9375px;
      text-align: center;
      letter-spacing: 3.18751px;
      text-transform: uppercase;
      color: color(red);
      padding: 10px 28px;
      position: relative;
      margin-top: 10px;

      @include respond-to-height(xxs) {
        font-size: 11px;
      }

      .entries__borders {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: 2px solid color(light-red);
        border-radius: 10px;
        opacity: 0.5;

        &:before {
          content: '';
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          width: 21px;
          height: 21px;
          background-color: color(light-red);
        }

        &:after {
          content: '';
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          width: 11px;
          height: 21px;
          background: url(/assets/svg/arrow-bottom.svg) no-repeat;
          background-size: contain;
          background-position: center;
        }
      }

      span {
        position: relative;
      }
    }

    .separator {
      margin-top: 20px;
      width: 100%;
      height: 1px;
      flex: 0 0 1px;
      background-color: color(border);

      @include respond-to-height(xxs) {
        margin-top: 14px;
      }
    }

    .labels-btns {
      margin-top: 24px;
      margin-bottom: 20px;

      @include respond-to-height(xs) {
        margin-top: 10px;
        margin-bottom: 5px;
      }

      p {
        margin: 0px;
        font-size: 11px;
        text-align: center;
        text-transform: uppercase;
        color: color(red);
        margin-bottom: 3px;
        padding: 0 15px;

        @include respond-to-height(xs) {
          font-size: 9px;
        }

        span {
          color: color(light-red);
        }
      }
    }

    .btns {
      display: flex;
      .common-button {
        &:first-child {
          margin-right: 27px;
        }
      }
    }

    .mention {
      color: color(red);
      font-size: 9px;
      line-height: 15px;
      margin: 0;
      margin-top: 15px;
      padding: 0 5px;
      text-align: center;
      a {
        color: color(red);
      }
      @include respond-to-height(xs) {
        margin-top: 5px;
        line-height: 9px;
      }
    }
  }
}
</style>
