
import {
  computed,
  nextTick,
  onActivated,
  onDeactivated,
  onMounted,
  ref
} from 'vue'
import anime from 'animejs/lib/anime.es.js'
import store from '@/store'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'

export default {
  setup() {
    const isOpen = ref(false)
    const bars = ref(null)
    const howler = useHowler()

    const sound = howler.$getSound('wouf', false, {
      srcs: [FX_URL + 'popup.mp3'],
      preload: true,
      html5: false,
      preloadCallback: () => {}
    })

    const toggleButton = () => {
      nextTick(openMenu)

      sound.play()
    }

    onMounted(() => {
      onEnter()
    })

    onActivated(() => {
      onEnter()
    })

    onDeactivated(() => {
      // closeMenu()
      isOpen.value = false
    })

    const onEnter = (delay = 500) => {
      anime({
        targets: '.bars line',
        duration: 600,
        scaleX: [0, 1],
        delay: anime.stagger(133, { direction: 'reverse', start: delay }),
        easing: 'cubicBezier(0.67, 0, 0.25, 1)'
      })
    }

    const openMenu = () => {
      if (store.getters['global/menuOpen']) return
      store.commit('global/setMenu', true)

      const tl = anime.timeline()
      tl.add(
        {
          targets: '.circle',
          duration: 367,
          easing: 'cubicBezier(0.4, 0, 0.83, 1)',
          translateX: ['-50%', '-50%'],
          translateY: ['-50%', '-50%'],
          keyframes: [
            { opacity: 1, duration: 0 },
            {
              opacity: 0,
              duration: 367,
              easing: 'cubicBezier(0.33, 0, 0.67, 1)'
            }
          ],
          scale: [0, 1]
        },
        33
      ).add(
        {
          targets: '.cross',
          duration: 120,
          easing: 'cubicBezier(0.4, 0, 0.83, 1)',
          scale: [0, 1]
        },
        66
      )
    }

    return {
      isOpen,
      toggleButton,
      bars
    }
  }
}
