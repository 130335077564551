<template>
  <div class="video-overlay" :class="{ open: open }" @click="$emit('closed')">
    <div class="video-overlay-frame">
      <iframe
        v-if="open"
        src="https://www.youtube.com/embed/Kas2WEMyino?autoplay=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'VideoOverlay',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closed']
}
</script>
<style lang="scss">
.video-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  pointer-events: none;
  z-index: -1;

  background: rgba(#000, 0.8);

  transition: opacity 0.25s ease, z-index 0.25s step-end;

  &.open {
    z-index: 100;
    pointer-events: all;
    opacity: 1;

    transition: opacity 0.25s ease, z-index 0.25s step-start;
  }

  &-frame {
    position: relative;
    padding-bottom: 56.25%; // 16 by 9
    width: 100%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
