<template>
  <div class="score-rank">
    <span class="score-rank__score">{{ score }}</span>
    <span class="score-rank__place" v-html="rank" />
  </div>
</template>

<script lang="ts">
import { indicator } from 'ordinal'
import store from '@/store'
import { computed, onBeforeMount, ref } from '@vue/runtime-core'
import { checkPage } from '@/hooks/global'

export default {
  setup() {
    checkPage()
    const SCORE_API = process.env.VUE_APP_SCORE_API

    const score = computed(() => store.getters['game/score'])
    const rank = ref(null)
    const simpleRank = ref(null)

    const getRank = async () => {
      const rawResponse = await fetch(`${SCORE_API}score/test/${score.value}`)
      const { position } = await rawResponse.json()

      const indi = indicator(position + 1)
      simpleRank.value = position + 1
      rank.value = `${position + 1}<sup>${indi}</sup>`
    }

    onBeforeMount(async () => {
      await getRank()
    })

    return { score, rank, simpleRank }
  }
}
</script>

<style lang="scss">
.score-rank {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  &__score {
    font-size: 45px;
    color: color(light-red);
    text-shadow: 0px 2px 0px #ffffff;
    margin-right: 20px;
  }

  &__place {
    font-family: font(heading);
    font-size: 20px;
    color: color(red);
    letter-spacing: 0.8px;
    border: 1px solid rgba(color(light-red), 0.25);
    border-radius: 9px;
    padding: 5px 11px;
    margin-top: 2px;

    sup {
      font-size: 11px;
    }
  }
}
</style>
