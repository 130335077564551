<template>
  <div class="leaderboard-table" :class="{ siblings }">
    <ul class="t-list t-list--best">
      <li
        v-for="entry in filteredBest"
        :key="entry.position"
        class="leaderboard-table-entry"
        :class="{ 'is-user': entry.id === id }"
      >
        <span
          class="leaderboard-table-number"
          :class="{ bigger: isSmallPos(entry.position) }"
        >
          {{ entry.position + 1 }}
        </span>
        <span class="leaderboard-table-username">{{ entry.username }}</span>
        <span class="leaderboard-table-score">{{ entry.score }}</span>
      </li>
    </ul>

    <span v-if="siblings && !userInTop" class="dots">...</span>

    <ul v-if="siblings && !userInTop" class="t-list t-list--user">
      <li
        v-for="entry in siblings"
        :key="entry.position"
        class="leaderboard-table-entry"
        :class="{ 'is-user': entry.id === id }"
      >
        <span
          class="leaderboard-table-number"
          :class="{ bigger: isSmallPos(entry.position) }"
        >
          {{ entry.position + 1 }}
        </span>
        <span class="leaderboard-table-username">{{ entry.username }}</span>
        <span class="leaderboard-table-score">{{ entry.score }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, onDeactivated, onMounted } from '@vue/runtime-core'
import anime from 'animejs/lib/anime.es.js'
import { useGtm } from 'vue-gtm'

export default {
  props: {
    best: {
      type: Array,
      default: null
    },
    siblings: {
      type: Array,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },

  setup(props) {
    const gtm = useGtm()
    const filteredBest = computed(() => {
      return props.siblings ? props.best.slice(0, 3) : props.best
    })

    const isSmallPos = pos => {
      return pos < 10
    }

    const userInTop = computed(() => {
      return props.best.find(item => item.id === props.id)
    })

    onMounted(() => {
      anime.set('.fake-borders', {
        opacity: 0
      })

      onEnter()
    })

    onDeactivated(() => {
      anime.set('.fake-borders', {
        opacity: ''
      })
    })

    const onEnter = () => {
      gtm.trackEvent({
        event: 'vpv',
        VPV: 'leaderboard'
      })
      const tl = anime.timeline()
      tl.add({
        targets:
          '.t-list--best .leaderboard-table-entry, .dots, .t-list--user .leaderboard-table-entry',
        duration: 333,
        delay: anime.stagger(67),
        opacity: [0, 1],
        easing: 'cubicBezier(0.33, 0, 0.67, 1)'
      })
    }

    return { filteredBest, isSmallPos, userInTop }
  }
}
</script>

<style lang="scss">
.leaderboard-table {
  &-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid #f28e6c;
    border-width: 0px 0px 1px 0px;

    &:first-child {
      border-top: 1px solid #f28e6c;
      margin-top: -1px;
    }
    // &:last-child {
    //   border-bottom: none;
    // }
  }

  &-number {
    font-family: font(heading);
    color: color(light-red);
    font-size: 35px;
    line-height: 28px;
    border-right: 1px solid #f28e6c;
    text-shadow: 0px 2px 0px #ffffff;
    padding: 14px 0px;
    text-align: center;
    width: 90px;
    flex: 0 0 90px;
  }

  &-username {
    font-family: font(heading);
    color: color(light-red);
    font-size: 18px;
    line-height: 28px;
    margin-right: auto;
    padding: 14px 0px;
    flex: 0 0 auto;
    max-width: 120px;
    margin-left: 19px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
  }

  &-score {
    font-size: 15px;
    line-height: 100%;
    text-transform: uppercase;
    color: color(red);
    flex: 0 0 auto;
    padding-right: 31px;
  }

  &.siblings {
    .t-list {
      &.t-list--best {
        .leaderboard-table-entry {
          &:last-child:not(.is-user) {
            border-bottom: 1px solid #f28e6c;
          }
        }
      }

      &.t-list--user {
        margin-top: 16px;
        .leaderboard-table-entry {
          &:last-child:not(.is-user) {
            border-bottom: 1px solid #f28e6c;
          }

          .leaderboard-table-number {
            font-size: 18px;
            color: color(red);
            text-shadow: none;
          }
          .leaderboard-table-username {
            color: color(red);
          }
        }
      }

      .leaderboard-table-entry {
        &.is-user {
          background-color: #fff;
          margin-left: -8px;
          margin-right: -8px;
          margin-top: -2px;
          position: relative;
          border: 2px solid #d8635f;
          border-radius: 5px;

          &:first-child {
            margin-top: 4px;
          }

          .leaderboard-table-number,
          .leaderboard-table-username {
            padding: 24px 0;
            color: color(light-red);
          }

          .leaderboard-table-number {
            font-size: 21px;
            flex: 0 0 97px;
            border-right: 2px solid #d8635f;

            &.bigger {
              font-size: 35px;
            }
          }

          .leaderboard-table-username {
            font-size: 25px;
          }

          .leaderboard-table-score {
            color: color(light-red);
            font-size: 20px;
          }
        }
      }
    }
  }

  .dots {
    font-family: font(heading);
    color: #d8635f;
    font-size: 35px;
    line-height: 18px;
    opacity: 0.5;
    padding-left: 90px;
  }
}
</style>
