<template>
  <transition name="about" mode="out-in" :appear="true" @enter="onEnter">
    <div class="about about-beige">
      <site-header :logo="true" :logo-left-aligned="true" :borders="true" />
      <div class="scroll-container">
        <div class="scroll-border">
          <div class="about-bottle">
            <img
              ref="about"
              src="/assets/images/about/high-west-about.png"
              alt=""
              class="img-about"
            />
            <img
              src="/assets/images/about/high-west-bottle.png"
              alt=""
              class="img-bottle"
            />
          </div>

          <p class="about-intro" v-html="t('About.main-about')" />

          <button
            class="about-video-overlay-button"
            type="button"
            @click="openVideoOverlay"
          >
            <img
              class="about-video-thumbnail"
              src="/assets/images/about/about-video-thumbnail.png"
              alt=""
            />
          </button>

          <video-overlay :open="videoOpen" @closed="closeVideoOverlay" />

          <p class="about-copy" v-html="t('About.par1-about')" />

          <p ref="quote" class="about-quote" v-html="t('About.main2-about')" />

          <div ref="divider">
            <horseshoe-divider class="about-horseshoe-divider" />
          </div>

          <p class="about-copy" v-html="t('About.par2-about')" />

          <img
            ref="bottom"
            width="344"
            height="363"
            src="/assets/images/about/about-image-bottom.jpg"
            alt=""
            class="about-image-bottom"
          />
        </div>
        <p class="mention" v-html="t('About.mention')" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { checkPage } from '@/hooks/global'
import SiteHeader from '@/components/GameHeader.vue'
import VideoOverlay from '@/components/VideoOverlay.vue'
import HorseshoeDivider from '@/assets/svg/horseshoe-divider.svg'
import store from '@/store'
import { useGtm } from 'vue-gtm'

import { inject, onBeforeUnmount, onDeactivated, ref } from 'vue'
import anime from 'animejs/lib/anime.es.js'
import { clamp, map } from '@/webgl/math'

interface positions {
  quote?: DOMRect
  divider?: DOMRect
  bottom?: DOMRect
}

const IMGS = [
  {
    name: 'about-image-bottom.jpg',
    url: '/assets/images/about/about-image-bottom.jpg'
  },
  {
    name: 'about-video-thumbnail.png',
    url: '/assets/images/about/about-video-thumbnail.png'
  },
  {
    name: 'high-west-about.png',
    url: '/assets/images/about/high-west-about.png'
  },
  {
    name: 'high-west-bottle.png',
    url: '/assets/images/about/high-west-bottle.png'
  }
]

export default {
  name: 'About',

  components: {
    SiteHeader,
    HorseshoeDivider,
    VideoOverlay
  },

  setup() {
    const gtm = useGtm()
    const check = checkPage()

    const t = inject('$t')

    const scrollY = {
      dist: 0,
      current: 0
    }

    const positions: positions = {}

    let raf = null

    const loaded = ref(false)
    const videoOpen = ref(false)
    const about = ref(null)
    const quote = ref(null)
    const divider = ref(null)
    const bottom = ref(null)

    const openVideoOverlay = () => {
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'playvideo',
        eventLabel: 'Video Player',
        eventValue: '',
        eventCategory: 'about'
      })
      videoOpen.value = true
    }

    const closeVideoOverlay = () => {
      videoOpen.value = false
    }

    const onTick = () => {
      const vp = store.getters['global/getViewportSize']
      scrollY.current += (scrollY.dist - scrollY.current) * 0.25

      const aboutY = scrollY.current * 0.8
      const aboutOpacity = map(scrollY.current, 0, vp.height * 0.6, 1, 0)

      if (aboutOpacity > 0) {
        about.value.style.transform = `translate3d(-50%, ${aboutY}px, 0)`
        about.value.style.opacity = clamp(aboutOpacity, 0, 1)
      }

      const quoteOpacity = map(
        scrollY.current,
        positions.quote.top - vp.height * 0.9,
        positions.quote.top - vp.height * 0.6,
        0,
        1
      )
      quote.value.style.opacity = clamp(quoteOpacity, 0, 1)

      const dividerOpacity = map(
        scrollY.current,
        positions.divider.top - vp.height * 0.9,
        positions.divider.top - vp.height * 0.5,
        0,
        1
      )
      divider.value.style.opacity = clamp(dividerOpacity, 0, 1)

      const bottomOpacity = map(
        scrollY.current,
        positions.bottom.top - vp.height * 0.8,
        positions.bottom.top - vp.height * 0.6,
        0,
        1
      )
      bottom.value.style.opacity = clamp(bottomOpacity, 0, 1)

      raf = window.requestAnimationFrame(onTick)
    }

    const onScroll = () => {
      scrollY.dist = window.scrollY
    }

    const getPositions = () => {
      const quoteBB = quote.value.getBoundingClientRect()
      positions.quote = quoteBB
      const dividerBB = divider.value.getBoundingClientRect()
      positions.divider = dividerBB
      const bottomBB = bottom.value.getBoundingClientRect()
      positions.bottom = bottomBB
    }

    const loadAssets = async () => {
      return await Promise.all(
        IMGS.map(asset => {
          return new Promise(resolve => {
            const image = new Image()
            image.addEventListener('load', () => {
              resolve(image)
              console.log(asset.url, 'loaded')
            })
            image.src = asset.url
          })
        })
      )
    }

    const onEnter = async (el, done) => {
      if (check)
        gtm.trackEvent({
          event: 'vpv',
          VPV: 'about'
        })
      window.scrollTo(0, 0)
      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: true
      })

      anime.set('.fake-bg', {
        backgroundColor: '#fcd39f'
      })

      window.addEventListener('scroll', onScroll)
      onScroll()

      await loadAssets()
      loaded.value = true
      done()
      setTimeout(() => {
        document.body.style.background = '#fcd39f'
        getPositions()
        onTick()
      }, 100)
    }

    // onMounted(async () => {

    // })

    const destroy = () => {
      document.body.style.background = ''
      store.commit('global/toggleFrontGridVisibility', {
        isFrontGridHidden: false
      })

      anime.set('.fake-bg', {
        backgroundColor: ''
      })

      window.removeEventListener('scroll', onScroll)
      window.cancelAnimationFrame(raf)
    }

    onDeactivated(() => {
      destroy()
    })

    onBeforeUnmount(() => {
      destroy()
    })

    return {
      videoOpen,
      openVideoOverlay,
      closeVideoOverlay,
      about,
      divider,
      quote,
      bottom,
      loaded,
      onEnter,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  display: flex;

  &.about-enter-to {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    width: 100%;
  }

  .scroll-container {
    padding: 0px;
    line-height: 0px;
    border: 0;
    .scroll-border {
      padding: 0px 14px;
      border: 1px solid #f28e6c;
      border-width: 0px 1px 1px 1px;
      border-radius: 0px 0px 15px 15px;
      position: relative;
      width: 100%;
    }
  }

  .mention {
    padding: 0px 14px;
    color: color(red);
    font-size: 9px;
    line-height: 15px;
    margin: 0;
    margin-top: 15px;
    a {
      color: color(red);
    }
  }

  &-image-bottle {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
  }

  &-bottle {
    position: relative;

    .img-about {
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 300px;
      height: auto;
      will-change: all;
    }

    .img-bottle {
      position: relative;
      z-index: 1;
      width: calc(100% + 30px);

      @include respond-to(xs) {
        width: calc(100% + 27px);
      }
    }
  }

  p {
    z-index: 1;
  }

  &-pink {
    background-color: color(light-red);
  }

  &-brown {
    background-color: color(brown);
  }

  &-beige {
    background-color: color(beige);
  }

  &-orange {
    background-color: color(orange-mid);
  }

  &-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 67px;
    z-index: 10;

    &::after {
      position: absolute;
      top: 15px;
      right: 15px;
      bottom: 0;
      left: 15px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-left: 1px solid color(border);
      border-right: 1px solid color(border);
      border-top: 1px solid color(border);
      content: '';

      @include respond-to(xs) {
        top: 12px;
        left: 12px;
        right: 12px;
      }
    }
  }

  &-heading {
    color: transparent;
    -webkit-text-stroke: 1px #a01925;

    font-family: font(heading);
    font-size: 75px;
    letter-spacing: 5px;
    z-index: 0;
  }

  &-intro {
    margin: 0 0 38px;
    margin-bottom: 53px;
    text-align: center;

    color: color(red);

    font-size: fontSize(lg);
    line-height: 1.4;
  }

  &-video-thumbnail {
    // margin-left: -15px;
    // margin-right: -15px;
    width: 100%;
    height: auto;
  }

  &-copy {
    margin: 0 20px 88px;

    color: color(red);

    font-size: fontSize(md);
    line-height: 1.8;
    text-align: center;
    position: relative;
  }

  &-quote {
    color: color(red);

    font-family: font(heading);
    font-size: fontSize(lg);
    line-height: 25px;
    font-weight: 700;
    text-shadow: 0 2px white;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &-horseshoe-divider {
    width: 168px;
    /* height: 32px; */
    margin: 0 auto;
    z-index: 1;
    display: block;
    margin-bottom: 42px;
  }

  &-image-bottom {
    width: calc(100% + 28px);
    height: auto;
    margin: 0 -14px;
    border-radius: 14px;
    z-index: -1;
    margin-top: -180px;
    will-change: opacity;
  }

  &-video-overlay-button {
    display: block;
    margin: 0 -14px;
    margin-bottom: 66px;
    padding: 0;
    background: transparent;
    border: 0;
    -webkit-tap-highlight-color: transparent;

    &:focus,
    &:active {
      outline: none;
    }
  }
}
</style>
